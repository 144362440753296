import { useQuery, ApolloQueryResult, ApolloError } from '@apollo/client';
import { gql } from 'graphql-tag';
import { useState } from 'react';
import {
  VisitDocumentWithNextDocumentIdWithCustomVisitType,
  VisitDocumentWithNextDocumentIdWithCustomVisitTypeVariables,
} from './__generated__/VisitDocumentWithNextDocumentIdWithCustomVisitType';

export const GET_DOCUMENT_WITH_NEXT_DOCUMENT_WITH_CUSTOM_VISIT_TYPE = gql`
  query VisitDocumentWithNextDocumentIdWithCustomVisitType(
    $documentId: ID
    $visitType: String!
    $visitLocationId: ID!
    $visitInviteId: ID!
  ) {
    visitDocumentWithNextDocumentIdWithCustomVisitType(
      documentId: $documentId
      visitType: $visitType
      visitLocationId: $visitLocationId
      visitInviteId: $visitInviteId
    ) {
      id
      documentName
      documentBody
      isRequired
      selectedOutcome {
        id
        outcome
      }
      possibleOutcomes {
        id
        outcome
      }
      completionStatus
      workflowId
      nextDocumentId
    }
  }
`;

const isNoWorkflowFoundError = (error: unknown): boolean => {
  return (
    error instanceof ApolloError &&
    error.graphQLErrors?.[0]?.extensions?.code === 'BAD_USER_INPUT'
  );
};

type UseDocumentDetails = {
  data: VisitDocumentWithNextDocumentIdWithCustomVisitType | undefined;
  loading: boolean;
  error: ApolloError | null;
  refetch: (
    variables?:
      | Partial<VisitDocumentWithNextDocumentIdWithCustomVisitTypeVariables>
      | undefined
  ) => Promise<
    ApolloQueryResult<VisitDocumentWithNextDocumentIdWithCustomVisitType>
  >;
};

export const useDocumentDetailsWithCustomVisitType = (
  visitType: string,
  visitLocationId: string,
  visitInviteId: string,
  documentId?: string
): UseDocumentDetails => {
  const [error, setError] = useState<ApolloError | null>(null);
  const { data, loading, refetch } = useQuery<
    VisitDocumentWithNextDocumentIdWithCustomVisitType,
    VisitDocumentWithNextDocumentIdWithCustomVisitTypeVariables
  >(GET_DOCUMENT_WITH_NEXT_DOCUMENT_WITH_CUSTOM_VISIT_TYPE, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'cache-and-network',
    errorPolicy: 'all',
    variables: {
      documentId,
      visitType,
      visitLocationId,
      visitInviteId,
    },
    // Don't bother with the query if we don't have the necessary ids
    skip: !visitType || !visitLocationId || !visitInviteId,
    onError: (e) => {
      // Swallow empty workflow related errors so they don't trigger an error state
      if (!isNoWorkflowFoundError(e)) {
        setError(e);
        throw e;
      }
    },
  });

  return {
    data,
    loading,
    error,
    refetch,
  };
};
