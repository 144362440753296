import { createContext, ReactNode, useContext, useMemo } from 'react';
import {
  useDelayedVisitCheckout,
  CheckoutMutation,
  CheckoutMutationResult,
} from './hooks';

type CheckOutContextValue = {
  checkOutMutation: CheckoutMutation;
  mutationResult: CheckoutMutationResult;
};

const CheckOutContext = createContext<CheckOutContextValue | undefined>(
  undefined
);

export const CheckOutContextProvider = ({
  children,
}: {
  children: ReactNode;
}): JSX.Element => {
  const [checkOutMutation, mutationResult] = useDelayedVisitCheckout();

  const contextValue = useMemo(
    () => ({
      checkOutMutation,
      mutationResult,
    }),
    [checkOutMutation, mutationResult]
  );

  return (
    <CheckOutContext.Provider value={contextValue}>
      {children}
    </CheckOutContext.Provider>
  );
};

export function useCheckOutContext(): CheckOutContextValue {
  const context = useContext(CheckOutContext);
  if (!context) {
    throw new Error(
      'useCheckOutContext must be used within a CheckOutContextProvider'
    );
  }
  return context;
}
