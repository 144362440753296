import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled/macro';
import { Body05Styles, Button, Colors } from '@robinpowered/design-system';
import ChevronDownOutline from '@robinpowered/icons/ChevronDownOutline';
import USFlag from './icons/us-flag.svg';
import EUFlag from './icons/eu-flag.svg';
import Checkmark from './icons/checkmark.svg';
import { useState } from 'react';

type Language = {
  displayName: string;
  url: string;
  icon: string;
};

const regions: Language[] = [
  {
    displayName: 'United States',
    url: 'https://arrivals.robinpowered.com',
    icon: USFlag,
  },
  {
    displayName: 'European Union',
    url: 'https://arrivals.robinpowered.eu',
    icon: EUFlag,
  },
];

export const RegionPicker: React.FC = () => {
  const { t } = useTranslation('registerInstructions');
  const [shouldSelectorShow, setShouldSelectorShow] = useState<boolean>(false);
  // Find the active region based on URL, default to US
  const activeRegion =
    regions.find((r) => window.location.href.includes(r.url)) || regions[0];

  return (
    <Container>
      <Button
        variant="secondary"
        size="default"
        name={t('region_picker.name')}
        onClick={() => setShouldSelectorShow(!shouldSelectorShow)}
        style={{
          borderRadius: 8,
          padding: 16,
          marginBottom: 8,
        }}
      >
        <Icon src={activeRegion.icon} />
        {activeRegion.displayName}
        <StyledChevron size={18} />
      </Button>

      {shouldSelectorShow && (
        <Wrapper>
          <Header>{t('region_picker.name')}</Header>
          {regions.map((region) => (
            <Entry
              active={region.displayName === activeRegion.displayName}
              key={region.displayName}
              onClick={() => {
                if (window.location.href.includes(region.url)) {
                  setShouldSelectorShow(false);
                } else {
                  window.location.href = region.url;
                }
              }}
            >
              {region.displayName}
            </Entry>
          ))}
        </Wrapper>
      )}
    </Container>
  );
};

export const Container = styled.div`
  position: relative;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 8px;
`;

const Icon = styled.img`
  width: 22px;
  margin-right: 8px;
`;

const Wrapper = styled.ul`
  background-color: ${Colors.White0};
  border: 1px solid ${Colors.Tan70};
  box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.15), 0px 2px 12px rgba(0, 0, 0, 0.06);
  border-radius: 8px;
  padding: 8px 0;
  margin: 0;
  position: absolute;
  right: 0;
  top: 60px;
  width: 224px;
`;

const Header = styled.li`
  ${Body05Styles};
  color: ${Colors.Gray100};
  list-style: none;
  padding: 4px 16px;
`;

const Entry = styled.li`
  ${Body05Styles};
  color: ${Colors.Gray80};
  cursor: pointer;
  list-style: none;
  padding: 4px 16px 4px 40px;
  position: relative;

  ${({ active }: { active?: boolean }) =>
    active &&
    `
    background-color: ${Colors.Magenta100};
    color: ${Colors.White0};

    &::before {
      content: "";
      background: url(${Checkmark});
      background-repeat: no-repeat;
      height: 10px;
      width: 12px;
      position: absolute;
      margin-left: -20px;
      top: 50%;
      transform: translateY(-50%);
    }
  `};

  &:hover {
    background-color: ${Colors.Magenta100};
    color: ${Colors.White0};
  }
`;

const StyledChevron = styled(ChevronDownOutline)`
  margin-left: 8px;
`;
