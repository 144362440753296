import { SyntheticEvent, useEffect, useState } from 'react';
import {
  Body02Styles,
  Heading01,
  InputField,
} from '@robinpowered/design-system';
import styled from '@emotion/styled/macro';
import { useLazyQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import moment from 'moment-timezone';
import {
  GET_VISIT_QUERY,
  useTimedRedirect,
  useSelfRegistrationEnabled,
  useBadgePrinterIpAddress,
} from 'hooks';
import {
  BoundedErrorBadge,
  ContentWrapper,
  Header,
  InputWrapper,
} from 'components';
import { isValidEmail } from '../../utils';
import {
  useAmplitude,
  useBadgePrintingContext,
  useDeviceContext,
  usePageLoadedEvent,
} from 'contexts';
import {
  GetVisitsByEmailQuery,
  GetVisitsByEmailQueryVariables,
} from 'hooks/__generated__/GetVisitsByEmailQuery';
import { GuestInviteStatus } from '__generated__/globalTypes';

export const GuestLookup = (): JSX.Element => {
  const { t } = useTranslation('guestLookup');
  const { trackEmailLookup } = useAmplitude();
  const { location } = useDeviceContext();
  const { setPrinterIpAddress } = useBadgePrintingContext();
  const { data: selfRegData } = useSelfRegistrationEnabled();
  const isGuestSelfRegistrationEnabled =
    selfRegData?.isGuestSelfRegistrationEnabled ?? false;
  const history = useHistory();
  const [email, setEmail] = useState('');
  const [inputErrorText, setInputErrorText] = useState<string | null>(null);
  const { ipAddress } = useBadgePrinterIpAddress();
  const [getVisits, { loading, error, data }] = useLazyQuery<
    GetVisitsByEmailQuery,
    GetVisitsByEmailQueryVariables
  >(GET_VISIT_QUERY, {
    fetchPolicy: 'cache-and-network',
    onCompleted: (data) => {
      const hasNoVisits = data.getVisitsByEmail?.length === 0;
      if (hasNoVisits) {
        if (isGuestSelfRegistrationEnabled) {
          history.push('/guest-registration/details', {
            email,
          });
        } else {
          // Make the user submit another email
          setInputErrorText(t('visit_not_found'));
        }
      }
    },
  });
  const visits = data?.getVisitsByEmail;

  usePageLoadedEvent('guest-lookup', false);
  useTimedRedirect();

  useEffect(() => {
    if (ipAddress) {
      setPrinterIpAddress(ipAddress);
    }
  }, [ipAddress, setPrinterIpAddress]);

  useEffect(() => {
    if (visits?.length) {
      trackEmailLookup();

      if (visits.length === 1) {
        const visit = visits[0];
        if (
          visit.status === GuestInviteStatus.CHECKED_IN ||
          visit.status === GuestInviteStatus.CHECKED_OUT
        ) {
          history.push(`/${visit.id}/completed`);
        } else {
          history.push(`/${visit.id}/visit-details`);
        }
      } else {
        history.push('/visit-selection', { visits: visits });
      }
    }
  }, [visits, history, trackEmailLookup]);

  const searchForVisits = () => {
    getVisits({
      variables: {
        email,
        // Search for visits that start from the start of today through the end of the day
        minutesBefore: moment().diff(moment().startOf('d'), 'm'),
        minutesAfter: moment().endOf('d').diff(moment(), 'm'),
        locationId: location?.id,
      },
    });
  };

  return (
    <>
      <Header
        isLoading={loading}
        nextDisabled={!isValidEmail(email)}
        onPrevClick={() => {
          history.push('/');
        }}
        onNextClick={() => searchForVisits()}
      />

      {error && <BoundedErrorBadge message={t('server_error')} />}

      <ContentWrapper>
        <form
          onSubmit={(event) => {
            setInputErrorText(null);
            if (!isValidEmail(email)) {
              setInputErrorText(t('invalid_email'));
            } else {
              searchForVisits();
            }
            event.preventDefault(); // Don't redirect page
          }}
        >
          <Heading01 style={{ fontSize: '30px' }} mb="24px">
            {t('heading')}
          </Heading01>

          <Label>
            {t('email_label')}
            <InputWrapper>
              <InputField
                id="email-input"
                type="email"
                required
                autoComplete="off"
                onChange={(event: SyntheticEvent<HTMLInputElement>) => {
                  setEmail(event.currentTarget.value);
                }}
                error={!!inputErrorText}
                errorText={inputErrorText}
                value={email}
                mb={3}
                containerStyle={{
                  marginTop: '16px',
                }}
                style={{
                  fontSize: '26px',
                  padding: '20px 18px', // Will get 6px horizontal padding
                }}
              />
            </InputWrapper>
          </Label>
        </form>
      </ContentWrapper>
    </>
  );
};

const Label = styled.label`
  ${Body02Styles}
  display: block;
`;
