import { useQuery, ApolloError } from '@apollo/client';
import { gql } from 'graphql-tag';
import { GetBadgePrinterIpAddress } from './__generated__/GetBadgePrinterIpAddress';

type BadgePrinterIpOutput = {
  ipAddress?: string;
  loading: boolean;
  error?: ApolloError;
};

export const GET_BADGE_PRINTER_IP_ADDRESS = gql`
  query GetBadgePrinterIpAddress {
    badgePrinterIPAddress
  }
`;

export const useBadgePrinterIpAddress = (): BadgePrinterIpOutput => {
  const { data, loading, error } = useQuery<GetBadgePrinterIpAddress>(
    GET_BADGE_PRINTER_IP_ADDRESS,
    { fetchPolicy: 'cache-and-network' }
  );

  return {
    ipAddress: data?.badgePrinterIPAddress,
    loading: loading,
    error: error,
  };
};
