import { Button } from '@robinpowered/design-system';

/**
 * A button with Arrival Display specific styling
 */
export const ArrivalDisplayButton = withArrivalDisplayButtonStyling(Button);

// This looks like the most complex way to accomplish simple style decoration,
// but it allows us to export this component with all the TS typings of Button.
// eslint-disable-next-line @typescript-eslint/no-explicit-any
function withArrivalDisplayButtonStyling<T extends (...args: any[]) => any>(
  ButtonComponent: T
) {
  return function StyledButton(...args: Parameters<T>): ReturnType<T> {
    return ButtonComponent(
      {
        ...args[0],
        style: {
          borderRadius: '16px',
          height: 'auto',
          minWidth: '156px',
          fontSize: '22px',
          padding: '19px 32px',
          ...args[0].style,
        },
      },
      ...args.slice(1)
    );
  };
}
