import {
  useMutation,
  MutationTuple,
  MutationHookOptions,
  MutationFunctionOptions,
  ApolloError,
} from '@apollo/client';
import { gql } from 'graphql-tag';
import { useState } from 'react';
import {
  CheckOutGuestFromArrivalDisplay,
  CheckOutGuestFromArrivalDisplayVariables,
} from './__generated__/CheckOutGuestFromArrivalDisplay';

const ARTIFICIAL_DELAY_MILLIS = 3 * 1000; // 3 seconds

export const CHECKOUT_MUTATION = gql`
  mutation CheckOutGuestFromArrivalDisplay($guestInviteId: ID!) {
    checkOutGuestFromArrivalDisplay(guestInviteId: $guestInviteId)
  }
`;

type MutationFuncOptions = MutationFunctionOptions<
  CheckOutGuestFromArrivalDisplay,
  CheckOutGuestFromArrivalDisplayVariables
>;

export type CheckoutMutation = (options: MutationFuncOptions) => void;

export type CheckoutMutationResult = MutationTuple<
  CheckOutGuestFromArrivalDisplay,
  CheckOutGuestFromArrivalDisplayVariables
>[1] & {
  abortDelayedMutation: () => void;
  reset: () => void;
};

export const useDelayedVisitCheckout = (
  options?: MutationHookOptions<
    CheckOutGuestFromArrivalDisplay,
    CheckOutGuestFromArrivalDisplayVariables
  >
): [CheckoutMutation, CheckoutMutationResult] => {
  // The delay period before actually firing the mutation. The "fuse".
  const [mutationTimeoutID, setMutationTimeoutID] =
    useState<NodeJS.Timeout | null>(null);
  const [error, setError] = useState<ApolloError | undefined>();
  const [called, setCalled] = useState<boolean>(false);

  const [mutation, mutationResult] = useMutation<
    CheckOutGuestFromArrivalDisplay,
    CheckOutGuestFromArrivalDisplayVariables
  >(CHECKOUT_MUTATION, {
    onError: (e) => {
      setCalled(true);
      setError(e);
    },
    onCompleted: () => setCalled(true),
    ...options,
  });

  const delayedMutation = (options: MutationFuncOptions) => {
    const timeoutId = setTimeout(() => {
      mutation(options);
      setMutationTimeoutID(null);
    }, ARTIFICIAL_DELAY_MILLIS);

    // Set the loading state to true during the delay
    setMutationTimeoutID(timeoutId);
  };

  const reset = () => {
    setCalled(false);
    setError(undefined);
  };

  const abortDelayedMutation = () => {
    setMutationTimeoutID(null);
    if (mutationTimeoutID) {
      clearTimeout(mutationTimeoutID);
    }
  };

  return [
    delayedMutation,
    {
      ...mutationResult,
      loading: !!mutationTimeoutID || mutationResult.loading,
      abortDelayedMutation,
      // TODO: Remove the custom reset+error implementation when we upgrade Apollo to >3.4.x
      reset,
      error,
      called,
    },
  ];
};
