import styled from '@emotion/styled/macro';

type PropTypes = {
  companyName: string | null;
  imageSource: string;
};

export const CompanyLogo = ({
  companyName,
  imageSource,
}: PropTypes): JSX.Element => (
  <Logo
    alt={`${companyName} company logo`}
    src={imageSource}
    aria-label="company logo"
  />
);

export const Logo = styled.img`
  height: auto;
  max-height: 50px;
  max-width: 150px;
  object-fit: contain;
`;
