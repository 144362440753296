import { gql, useQuery } from '@apollo/client';
import { ApolloError } from '@apollo/client';
import { GetActiveVisitTypes } from './__generated__/GetActiveVisitTypes';

export const VISIT_TYPES_QUERY = gql`
  query GetActiveVisitTypes {
    getActiveVisitTypesForOrg
  }
`;

type UseVisitTypes = {
  visitTypes: string[];
  loading: boolean;
  error?: ApolloError;
  refetch: () => void;
};

export const useVisitTypes = (): UseVisitTypes => {
  const { data, loading, error, refetch } = useQuery<GetActiveVisitTypes>(
    VISIT_TYPES_QUERY,
    {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'cache-and-network',
    }
  );

  return {
    visitTypes: data?.getActiveVisitTypesForOrg ?? [],
    loading,
    error,
    refetch,
  };
};
