import { Component, ReactNode } from 'react';
import { UnauthedServerError } from 'components';
import { Sentry } from 'lib/sentry';

type ErrorBoundaryState = {
  error: unknown;
  errorInfo: unknown;
};

export class ErrorBoundary extends Component {
  state: ErrorBoundaryState = {
    error: null,
    errorInfo: null,
  };

  componentDidCatch(error: unknown, errorInfo: unknown): void {
    this.setState({
      error,
      errorInfo,
    });

    Sentry.captureException(error, {
      tags: {
        runtime: true,
      },
      extra: {
        errorInfo,
      },
    });
  }

  render(): ReactNode {
    if (this.state.error) {
      return (
        <UnauthedServerError
          onPressRetry={() => (window.location.href = '/')}
        />
      );
    }
    return this.props.children;
  }
}
