import { useTranslation } from 'react-i18next';
import { Row, SpinnerLoader } from '@robinpowered/design-system';
import { ArrivalDisplayButton, Heading00 } from 'components';

type CheckoutLoadingArgs = {
  onPressCancel: () => void;
};

export const CheckoutLoading: React.FC<CheckoutLoadingArgs> = ({
  onPressCancel,
}) => {
  const { t } = useTranslation('checkOut');

  return (
    <>
      <Heading00 style={{ fontSize: '30px' }} mb="24px">
        {t('loading_message')}
      </Heading00>
      <Row
        style={{
          alignItems: 'center',
          justifyContent: 'flex-end',
        }}
      >
        <SpinnerLoader style={{ marginRight: '24px' }} size={24} />
        <ArrivalDisplayButton variant="secondary" onClick={onPressCancel}>
          {t('cancel')}
        </ArrivalDisplayButton>
      </Row>
    </>
  );
};
