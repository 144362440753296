import { useLazyQuery, QueryTuple } from '@apollo/client';
import { gql } from 'graphql-tag';
import {
  GetVisitsByPartialEmailQuery,
  GetVisitsByPartialEmailQueryVariables,
} from './__generated__/GetVisitsByPartialEmailQuery';

export const GET_VISITS_QUERY = gql`
  query GetVisitsByPartialEmailQuery(
    $email: String!
    $minutesBefore: Int
    $minutesAfter: Int
    $locationId: String
    $resultLimiter: Int
  ) {
    getCheckedInVisitsByPartialEmail(
      email: $email
      minutesBefore: $minutesBefore
      minutesAfter: $minutesAfter
      locationId: $locationId
      resultLimiter: $resultLimiter
      includeCheckedOutVisits: true
    ) {
      id
      guest {
        id
        name
        email
      }
      status
      checkinOn
      checkoutOn
    }
  }
`;

export const useSearchVisits = (): QueryTuple<
  GetVisitsByPartialEmailQuery,
  GetVisitsByPartialEmailQueryVariables
> => {
  return useLazyQuery<
    GetVisitsByPartialEmailQuery,
    GetVisitsByPartialEmailQueryVariables
  >(GET_VISITS_QUERY, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
  });
};
