import { useQuery, QueryResult } from '@apollo/client';
import { gql } from 'graphql-tag';
import {
  GetGuestVisitById,
  GetGuestVisitByIdVariables,
} from './__generated__/GetGuestVisitById';

export const GET_VISIT_BY_ID = gql`
  query GetGuestVisitById($guestVisitId: ID!) {
    getGuestVisitById(guestVisitId: $guestVisitId) {
      id
      visitId
      customVisitType
      visitType
      status
      startTime
      checkinOn
      checkoutOn
      arrivalInstructions
      guest {
        id
        name
      }
      host {
        id
        avatar
        name
        email
      }
      arrivalLocation {
        id
        name
        address
        timezone
        isHealthCheckpointRequired
      }
      healthCheckpointSurveyResponse {
        id
        status
      }
    }
  }
`;

export const useVisitDetails = (
  guestVisitId: GetGuestVisitByIdVariables['guestVisitId']
): QueryResult<GetGuestVisitById, GetGuestVisitByIdVariables> =>
  useQuery<GetGuestVisitById, GetGuestVisitByIdVariables>(GET_VISIT_BY_ID, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'cache-and-network',
    variables: {
      guestVisitId,
    },
  });
