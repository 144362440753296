import styled from '@emotion/styled/macro';
import { Button, Heading01, Body01 } from '@robinpowered/design-system';
import { useTranslation } from 'react-i18next';
import { CenteredContent } from './CenteredContent';
import graphic from 'media/error-device-graphic.png';
import { UnauthenticatedHeader } from 'components';

export const UnauthedServerError = ({
  onPressRetry,
}: {
  onPressRetry: () => unknown;
}): JSX.Element => {
  const { t } = useTranslation('common');

  return (
    <CenteredContentNarrow>
      <UnauthenticatedHeader
        style={{
          // Spacing for the top of the screen
          // Spaces about 1/3 the free space, so content is off-center but near the top.
          flex: 0.25,
          minHeight: '60px',
        }}
      />
      <Graphic src={graphic} />
      <Heading01
        mb={3}
        style={{ fontSize: '26px', lineHeight: '36px', textAlign: 'center' }}
      >
        {t('error_page.heading')}
      </Heading01>
      <Body01 mb={4} style={{ lineHeight: '41px', textAlign: 'center' }}>
        {t('error_page.body')}
      </Body01>
      <PrimaryButton
        aria-label={t('error_page.cta')}
        variant="primary"
        onClick={() => onPressRetry()}
      >
        {t('error_page.cta')}
      </PrimaryButton>
    </CenteredContentNarrow>
  );
};

const CenteredContentNarrow = styled(CenteredContent)`
  padding: 42px 125px 80px;
  align-items: center;
`;

const Graphic = styled.img`
  margin-bottom: 95px;
  max-width: 100%;
`;

const PrimaryButton = styled(Button)`
  box-shadow: none;
  border-radius: 16px;
  padding: 16px 40px;
  font-size: 22px;
  line-height: 28px;
  height: auto;
`;
