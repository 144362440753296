import { useQuery, QueryResult } from '@apollo/client';
import { gql } from 'graphql-tag';
import {
  GetVisitsByEmailQuery,
  GetVisitsByEmailQueryVariables,
} from './__generated__/GetVisitsByEmailQuery';

export const GET_VISIT_QUERY = gql`
  query GetVisitsByEmailQuery(
    $email: String!
    $minutesBefore: Int
    $minutesAfter: Int
    $locationId: String
  ) {
    getVisitsByEmail(
      email: $email
      minutesBefore: $minutesBefore
      minutesAfter: $minutesAfter
      locationId: $locationId
    ) {
      id
      arrivalLocationId
      arrivalInstructions
      arrivalLocation {
        address
        name
        isHealthCheckpointRequired
        timezone
      }
      host {
        avatar
        name
      }
      visitType
      organization {
        name
        logo
      }
      guest {
        name
      }
      status
      startTime
    }
  }
`;

export const useVisitDetailsByEmail = (
  email: string
): QueryResult<GetVisitsByEmailQuery, GetVisitsByEmailQueryVariables> => {
  return useQuery<GetVisitsByEmailQuery, GetVisitsByEmailQueryVariables>(
    GET_VISIT_QUERY,
    {
      variables: {
        email,
      },
      fetchPolicy: 'cache-and-network',
      notifyOnNetworkStatusChange: true,
    }
  );
};
