import { useState, useEffect } from 'react';
import { ApolloError, useQuery } from '@apollo/client';
import { gql } from 'graphql-tag';
import { useDebounce } from 'react-use';
import { useDeviceContext } from 'contexts';
import {
  getUsersByKeyword,
  getUsersByKeywordVariables,
  getUsersByKeyword_getUsersByKeyword,
} from './__generated__/getUsersByKeyword';

const KEYWORD_DEBOUNCE_MS = 500;

export const MIN_KEYWORD_LENGTH = 3;

export const GET_USERS_BY_KEYWORD = gql`
  query getUsersByKeyword($organizationId: ID!, $keyword: String!) {
    getUsersByKeyword(organizationId: $organizationId, keyword: $keyword) {
      id
      name
      avatar
      primaryEmail {
        email
      }
    }
  }
`;

type UseHostSearch = {
  keyword: string;
  setKeyword: (keyword: string) => void;
  users: null | getUsersByKeyword_getUsersByKeyword[];
  loading: boolean;
  error?: ApolloError;
};

export const useHostSearch = (): UseHostSearch => {
  const { org } = useDeviceContext();
  const [keyword, setKeyword] = useState('');
  const [debouncedKeyword, setDebouncedKeyword] = useState(keyword);
  useDebounce(() => setDebouncedKeyword(keyword), KEYWORD_DEBOUNCE_MS, [
    keyword,
  ]);

  // State is controlled manually instead of by Apollo's useQuery..
  // We require finer control over when state is updated; for example,
  // if more letters are added to a keyword after the first results have come
  // back, we want to replace the old data with new. Apollo will set the state
  // to null before updating with new results, causing jank in the menu.
  const [state, setState] = useState<{
    loading: boolean;
    error?: ApolloError;
    users: getUsersByKeyword_getUsersByKeyword[] | null;
  }>({
    loading: false,
    users: null,
  });
  const setLoading = (loading: boolean) =>
    setState((state) => ({ ...state, loading }));
  const setUsers = (users: getUsersByKeyword_getUsersByKeyword[] | null) =>
    setState((state) => ({ ...state, loading: false, users }));
  const setError = (error: ApolloError) =>
    setState((state) => ({ ...state, loading: false, error }));

  const { refetch: getUsers } = useQuery<
    getUsersByKeyword,
    getUsersByKeywordVariables
  >(GET_USERS_BY_KEYWORD, {
    skip: true,
  });

  useEffect(() => {
    let cancelled = false;

    if (!org) return;

    if (keyword.length >= MIN_KEYWORD_LENGTH) {
      setLoading(true);
      getUsers({ organizationId: org.id, keyword })
        .then((result) => {
          if (!cancelled) {
            setUsers(result?.data?.getUsersByKeyword ?? []);
          }
        })
        .catch((err) => {
          if (!cancelled) {
            setError(err);
          }
        });
    } else {
      setUsers(null);
    }
    return () => {
      cancelled = true;
    };
  }, [debouncedKeyword, org?.id]); // eslint-disable-line react-hooks/exhaustive-deps

  return {
    keyword,
    setKeyword,
    ...state,
  };
};
