import {
  Button,
  Heading02,
  Body05,
  Dialog,
  Colors,
  Row,
} from '@robinpowered/design-system';
import { createPortal } from 'react-dom';
import { useTranslation } from 'react-i18next';

type PropTypes = {
  onCancel: () => void;
  onConfirm: () => void;
};

export const HealthCheckpointCancellationModal = ({
  onCancel,
  onConfirm,
}: PropTypes): JSX.Element | null => {
  const { t } = useTranslation('healthCheckpointCancellation');

  return createPortal(
    <>
      <Dialog.Overlay style={{ backgroundColor: 'rgba(0,0,0,0.3)' }} />
      <Dialog style={{ minWidth: '425px', zIndex: 3 }} {...undefined}>
        <Heading02 mb={3}>{t('heading')}</Heading02>
        <Body05 style={{ color: Colors.Gray100 }} mb={3} mr={2}>
          {t('body')}
        </Body05>
        <Row>
          <Button
            aria-label={t('back')}
            variant="secondary"
            ml="auto"
            onClick={() => onCancel()}
          >
            {t('cancel')}
          </Button>
          <Button
            aria-label={t('back')}
            variant="primary"
            ml={2}
            onClick={() => onConfirm()}
          >
            {t('back')}
          </Button>
        </Row>
      </Dialog>
    </>,
    document.body
  );
};
