import { Colors } from '@robinpowered/design-system';
import styled from '@emotion/styled/macro';
import { CompanyLogo } from 'components';
import { useDeviceContext } from 'contexts';
import { useTranslation } from 'react-i18next';
import { formatBadgeStartTime } from 'utils';
import { GetGuestVisitById_getGuestVisitById } from 'hooks/__generated__/GetGuestVisitById';

type PrintableBadgeProps = {
  visit: GetGuestVisitById_getGuestVisitById | null;
};

export const PrintableBadge = ({ visit }: PrintableBadgeProps): JSX.Element => {
  const { t } = useTranslation('badgePrint');
  const { org } = useDeviceContext();

  return (
    <Wrapper className="badge">
      {org?.logo && (
        <LogoWrapper>
          <CompanyLogo companyName={org?.name} imageSource={org.logo} />
        </LogoWrapper>
      )}
      <Heading>{visit?.guest.name}</Heading>
      <Body style={{ color: Colors.Gray80 }}>
        {t('host')}: {visit?.host.name}
      </Body>
      <Body style={{ color: Colors.Gray80 }}>
        {formatBadgeStartTime(
          visit?.startTime,
          visit?.arrivalLocation.timezone ?? null
        )}
      </Body>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  background-color: ${Colors.White0};
  border-radius: 24px;
  display: none;
  flex-direction: column;
  justify-content: center;
  padding: 6mm;
  transform-origin: bottom left;
  transform: translateY(-60mm) rotate(90deg);
  height: 60mm;
  width: 86mm;

  @page {
    margin: 0;
    padding: 0;
    size: 60mm 86mm;
  }

  @media print {
    display: flex;
  }
`;

const LogoWrapper = styled.div`
  max-width: 10mm;
`;

const Heading = styled.h1`
  color: ${Colors.Gray110};
  font-size: 24px;
  font-weight: 500;
  line-height: 28px;

  // Limit the guest name to 2 lines to prevent long
  // names causing breaking UI changes.
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
`;

const Body = styled.p`
  color: ${Colors.Gray80};
  font-size: 14px;
  line-height: 18px;
  margin: 0;
`;
