import { useEffect } from 'react';
import { useHistory } from 'react-router';

const DEFAULT_REDIRECT_SECONDS = 1000 * 60 * 5; // 5 minutes

export const useTimedRedirect = (
  route = '/',
  timeout: number = DEFAULT_REDIRECT_SECONDS
): void => {
  const history = useHistory();

  useEffect(() => {
    const timerId = setTimeout(() => history.push(route), timeout);
    return () => clearTimeout(timerId);
  }, [history]); // eslint-disable-line react-hooks/exhaustive-deps
};
