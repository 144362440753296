import { getUsersByKeyword_getUsersByKeyword } from 'pages/GuestRegistrationDetails/components';
import { createContext, ReactNode, useContext, useState } from 'react';

export type GuestDetails = {
  guestName: string;
  guestEmail: string;
  guestHost: getUsersByKeyword_getUsersByKeyword | null;
  guestVisitType: string | null;
  visitId: string;
  guestInviteId: string;
  healthCheckpointId: string;
};

export type GuestContextValue = GuestDetails & {
  setGuestName: (name: GuestDetails['guestName']) => void;
  setGuestEmail: (email: GuestDetails['guestEmail']) => void;
  setGuestHost: (host: GuestDetails['guestHost']) => void;
  setGuestVisitType: (visitType: GuestDetails['guestVisitType']) => void;
  setVisitId: (visitId: GuestDetails['visitId']) => void;
  setGuestInviteId: (guestInviteId: GuestDetails['guestInviteId']) => void;
  setHealthCheckpointId: (
    visitType: GuestDetails['healthCheckpointId']
  ) => void;
};

const SelfRegisteredGuestContext = createContext<GuestContextValue | undefined>(
  undefined
);

export const SelfRegisteredGuestContextProvider = ({
  children,
}: {
  children: ReactNode;
}): JSX.Element => {
  const [guestName, setGuestName] = useState<string>('');
  const [guestEmail, setGuestEmail] = useState<string>('');
  const [visitId, setVisitId] = useState<string>('');
  const [guestInviteId, setGuestInviteId] = useState<string>('');
  const [healthCheckpointId, setHealthCheckpointId] = useState<string>('');
  const [guestHost, setGuestHost] =
    useState<getUsersByKeyword_getUsersByKeyword | null>(null);
  const [guestVisitType, setGuestVisitType] = useState<string | null>(null);

  return (
    <SelfRegisteredGuestContext.Provider
      value={{
        guestName,
        guestEmail,
        guestHost,
        guestVisitType,
        visitId,
        guestInviteId,
        healthCheckpointId,
        setGuestName,
        setGuestEmail,
        setGuestHost,
        setGuestVisitType,
        setVisitId,
        setGuestInviteId,
        setHealthCheckpointId,
      }}
    >
      {children}
    </SelfRegisteredGuestContext.Provider>
  );
};

export function useSelfRegisteredGuestContext(): GuestContextValue {
  const context = useContext(SelfRegisteredGuestContext);
  if (!context) {
    throw new Error(
      'useSelfRegisteredGuestContext must be used within a SelfRegisteredGuestContextProvider'
    );
  }
  return context;
}
