import styled from '@emotion/styled/macro';
import { useTranslation } from 'react-i18next';
import {
  Column,
  Row,
  Body02,
  Body06,
  Heading04Styles,
  Colors,
} from '@robinpowered/design-system';
import moment from 'moment-timezone';
import { ArrivalDisplayButton } from 'components';
import { GetVisitsByPartialEmailQuery_getCheckedInVisitsByPartialEmail as Visit } from './hooks/__generated__/GetVisitsByPartialEmailQuery';
import { GuestInviteStatus } from '__generated__/globalTypes';

type VisitRowArgs = {
  visit: Visit;
  onPressCheckOut: () => void;
};

export const VisitRow: React.FC<VisitRowArgs> = ({
  visit,
  onPressCheckOut,
}) => {
  const { t } = useTranslation('checkOut');
  const isAlreadyCheckedOut =
    visit.status === GuestInviteStatus.CHECKED_OUT || !!visit.checkoutOn;
  const visitSummary = isAlreadyCheckedOut
    ? t('checked_out_on', { time: moment(visit.checkoutOn).format('h:mm a') })
    : t('checked_in_on', { time: moment(visit.checkinOn).format('h:mm a') });

  return (
    <RowContainer>
      <Column>
        <Body02 color={Colors.Gray100}>{visit.guest.name}</Body02>
        <Body06>{visitSummary}</Body06>
      </Column>
      {!isAlreadyCheckedOut && (
        <ArrivalDisplayButton
          style={{
            ...Heading04Styles,
            color: Colors.White0,
            height: '40px',
            minWidth: 'initial',
            borderRadius: '6px',
            paddingLeft: '16px',
            paddingRight: '16px',
            // TODO: Replace above styles ☝️ with a reusable component
            marginLeft: 'auto',
          }}
          onClick={onPressCheckOut}
          data-testid="visit-check-out-button"
        >
          {t('check_out')}
        </ArrivalDisplayButton>
      )}
    </RowContainer>
  );
};
const RowContainer = styled(Row)`
  display: flex;
  flex: 1;
  align-items: center;
  margin: 20px 24px 0 24px;
`;
