import styled from '@emotion/styled/macro';
import { Box, Colors } from '@robinpowered/design-system';

export const Section = styled(Box)<{ light?: boolean; boxed?: boolean }>`
  flex: 1;
  background-color: ${Colors.White0};
  overflow: hidden;

  ${(props) =>
    props.boxed &&
    `
      border: 1px solid ${Colors.Tan20};
      border-radius: 12px;
      `}}
`;

Section.defaultProps = {
  light: false,
  boxed: false,
};
