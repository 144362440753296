import styled from '@emotion/styled/macro';
import { Colors } from '@robinpowered/design-system';

export const InputField = styled.input<{ hasError: boolean }>`
  border: 2px solid ${Colors.Tan5};
  background-color: ${Colors.Tan5};
  border-radius: 8px;
  font-size: 22px;
  padding: 18px 24px;
  width: 100%;

  ${(props) => props.hasError && `border-color: ${Colors.RedProduct};`}

  &::placeholder {
    transition: color 200ms ease;
  }

  &:focus-within {
    border-color: ${Colors.Magenta100};
    outline: ${Colors.Magenta100};

    &::placeholder {
      color: transparent;
    }
  }
`;
