import { useQuery } from '@apollo/client';
import { gql } from '@apollo/client';
import { GetBadgePrintingFeatureFlag } from './__generated__/GetBadgePrintingFeatureFlag';

type FeatureFlagOutput = {
  enabled?: boolean;
};

const GET_BADGE_PRINTING_ENABLED_FEATURE_FLAG = gql`
  query GetBadgePrintingFeatureFlag {
    isFeatureFlagEnabledForDeviceOrg(flagType: ARRIVAL_DISPLAY_BADGE_PRINTING)
  }
`;

export const useBadgePrintingFeatureFlag: () => FeatureFlagOutput = () => {
  const { data } = useQuery<GetBadgePrintingFeatureFlag>(
    GET_BADGE_PRINTING_ENABLED_FEATURE_FLAG
  );

  return {
    enabled: data?.isFeatureFlagEnabledForDeviceOrg ?? false,
  };
};
