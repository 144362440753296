import { useRef, useCallback, useState } from 'react';
import styled from '@emotion/styled/macro';
import { useTranslation } from 'react-i18next';
import {
  Heading01,
  Checkbox,
  Colors,
  Button,
  Body05,
  SpinnerLoader,
} from '@robinpowered/design-system';
import {
  Header,
  BoxWrapper,
  MAX_WIDTH,
  BoundedErrorBadge,
  UnauthedServerError,
} from 'components';
import {
  useDocumentAgreement,
  useDocumentDetailsWithCustomVisitType,
  useHasViewedRef,
} from 'hooks';
import { useHistory } from 'react-router';
import {
  VisitDocumentAgreementStatus,
  VisitDocumentCompletionStatus,
} from '__generated__/globalTypes';

type DocumentProps = {
  visitType: string;
  locationId: string;
  visitId: string;
  inviteId: string;
  guestId: string;
  documentId: string;
  previousURL: string;
  nextDocumentURL: string;
  guestName: string;
  completeCheckIn: () => void;
};

export const Document = ({
  visitType,
  locationId,
  visitId,
  inviteId,
  guestId,
  documentId,
  previousURL,
  nextDocumentURL,
  guestName,
  completeCheckIn,
}: DocumentProps): JSX.Element => {
  const { t } = useTranslation('documentAgreement');
  const history = useHistory();
  const [sendCopyToGuest, setSendCopyToGuest] = useState<boolean>(false);
  // Allow us detect whether the full document has been viewed yet.
  // We're putting an empty div at the bottom of the document and detecting if
  // and when it enters the viewport.
  const { hasViewedRef, setRef, resetViewTrigger } = useHasViewedRef();
  const {
    data,
    loading: documentDetailsLoading,
    error: documentDetailsError,
    refetch: refetchDocumentDetails,
  } = useDocumentDetailsWithCustomVisitType(
    visitType,
    locationId,
    inviteId,
    documentId
  );
  const documentData = data?.visitDocumentWithNextDocumentIdWithCustomVisitType;
  const [
    agreeToDocumentMutation,
    { loading: documentAgreementLoading, error: documentAgreementError },
  ] = useDocumentAgreement({
    onCompleted: () => {
      resetViewTrigger();
      if (documentData?.nextDocumentId) {
        history.push(`${nextDocumentURL}/${documentData?.nextDocumentId}`);
      } else {
        completeCheckIn();
      }
    },
  });
  const documentBottomRef = useRef<HTMLElement | null>(null);
  const refCallback = useCallback(
    (node) => {
      documentBottomRef.current = node;
      setRef(node);
    },
    [setRef]
  );
  const scrollToBottom = () => {
    documentBottomRef.current?.scrollIntoView({
      behavior: 'smooth',
    });
  };
  /**
   * Triggers a mutation to mark the specified document as completed/agreed.
   * Upon successful completion, the user will be routed to the next document
   * to agree to, or checked in if there is no next document.
   */
  const agreeToDocument = () => {
    if (visitId && documentData && guestId) {
      agreeToDocumentMutation({
        variables: {
          visitId,
          visitInviteId: inviteId,
          guestId,
          workflowId: documentData.workflowId,
          // Documents can potentially have multiple possible outcomes.
          // Find the one that has the accept outcome, since we're agreeing.
          outcomeId:
            documentData.possibleOutcomes.find(
              (o) => o.outcome === VisitDocumentAgreementStatus.ACCEPT
            )?.id ?? '',
          sendCopyToGuest,
        },
      });
    }
    // TODO: log to sentry if no visitID/guestID is provided?
  };

  // Handle cases where a document is loaded that has already been agreed to.
  if (
    documentData?.completionStatus === VisitDocumentCompletionStatus.COMPLETE ||
    documentData === null
  ) {
    if (documentData?.nextDocumentId) {
      history.push(`${nextDocumentURL}/${documentData.nextDocumentId}`);
    } else {
      completeCheckIn();
    }
  }

  if (documentDetailsError) {
    return <UnauthedServerError onPressRetry={refetchDocumentDetails} />;
  }

  return (
    <ScreenContainer>
      <Header
        onPrevClick={() => {
          history.push(previousURL);
        }}
        onNextClick={() => {}}
        nextHidden
      />

      {documentAgreementError && (
        <BoundedErrorBadge message={t('errors.document_agreement')} />
      )}

      <Heading01
        id="heading"
        display="flex"
        justifyContent="center"
        mt="34px"
        mb="32px"
      >
        {t('agreement_heading')}
      </Heading01>

      <BoxContainer>
        {documentDetailsLoading && !documentData && (
          <FlexWrapper>
            <SpinnerLoader />
          </FlexWrapper>
        )}

        {documentData && (
          <>
            <DocumentContainer aria-label={t('document_container_label')}>
              <DocumentText>{documentData.documentBody}</DocumentText>
              {/* Empty div for detecting if the user read the whole document */}
              <div ref={refCallback} />
            </DocumentContainer>

            <Footer>
              {hasViewedRef ? (
                <div>
                  <FooterMessageWrapper>
                    <Body05>
                      {t('agree_description', { fullName: guestName })}
                    </Body05>
                  </FooterMessageWrapper>

                  <CheckBoxWrapper>
                    <Checkbox
                      id="guestCopyCheckbox"
                      label={t('send_guest_copy')}
                      handleClick={() => setSendCopyToGuest(!sendCopyToGuest)}
                      checked={sendCopyToGuest}
                      disabled={false}
                      indeterminate={false}
                      py={2}
                    />
                    <ArrivalDisplayButton
                      isLoading={documentAgreementLoading}
                      onClick={agreeToDocument}
                      name={t('agree')}
                    >
                      {t('agree')}
                    </ArrivalDisplayButton>
                  </CheckBoxWrapper>
                </div>
              ) : (
                <FullFooterButton variant="tertiary" onClick={scrollToBottom}>
                  {t('scroll_down')}
                </FullFooterButton>
              )}
            </Footer>
          </>
        )}
      </BoxContainer>
    </ScreenContainer>
  );
};

const FlexWrapper = styled.div`
  align-items: center;
  display: flex;
  flex: 1;
`;

const CheckBoxWrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: flex-end;
  flex: 1;
  padding-top: 16px;

  label {
    color: ${Colors.Gray80};
  }
`;

const DocumentContainer = styled.div`
  align-self: flex-start;
  flex: 1;
  overflow: auto; /* Make long documents scroll */
  -webkit-overflow-scrolling: touch; /* Might enable momentum scrolling on iPads */
  width: 100%;
  padding: 80px 64px;
`;

const DocumentText = styled(Body05)`
  display: block; /* Allows long words to be split */
  white-space: pre-wrap; /* Support for line breaks */
  overflow-wrap: break-word; /* Allows long words to be split */
  padding-bottom: 80px;
`;

const Footer = styled.div`
  /* Static height avoids jumpy UI when the footer content changes */
  min-height: 80px;
  display: flex;
  flex-shrink: 0;
  align-self: stretch;
  align-items: center;
  justify-content: space-between;
  padding: 24px 64px 56px;
`;

// TODO: Is this style used elsewhere in the app?
const ArrivalDisplayButton = styled(Button)`
  border-radius: 16px;
  padding: 16px 62px;
  margin-left: 24px;
`;

const FullFooterButton = styled(Button)`
  border: 1px solid ${Colors.Tan70};
  border-radius: 16px;
  align-self: stretch;
  flex: 1 0;
  font-size: 20px;
  padding: 16px;
  &:focus {
    /* A11y on focus */
    border: 2px solid ${Colors.Magenta100};
    box-shadow: none;
  }
`;

const FooterMessageWrapper = styled.div`
  background-color: ${Colors.Tan5};
  border-radius: 16px;
  padding: 24px;
`;

const ScreenContainer = styled.div`
  max-height: 100vh;
  max-width: 100vw;
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow: auto;
`;

const BoxContainer = styled(BoxWrapper)`
  flex: 1;
  width: 80%;
  max-width: ${MAX_WIDTH};
  overflow-y: auto; /* Allow contents to scroll */
  flex-direction: column;
  margin-bottom: 75px;
  padding: 0;
  display: flex;
  align-self: center;
  align-items: center;
`;
