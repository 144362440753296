import {
  useRef,
  ChangeEvent,
  FocusEventHandler,
  KeyboardEventHandler,
  KeyboardEvent,
} from 'react';
import styled from '@emotion/styled/macro';
import { Input } from '@robinpowered/design-system';
import { ApolloError } from '@apollo/client';
import { useClickAway } from 'react-use';
import { HostSelectResults } from './HostSelectResults';
import { getUsersByKeyword_getUsersByKeyword } from './__generated__/getUsersByKeyword';
import { useKeyboardNavigation } from './useKeyboardNavigation';

const HTML_MENU_ID = 'host-select-results';

type PropTypes = {
  id: string;
  loading: boolean;
  value: string;
  users: null | getUsersByKeyword_getUsersByKeyword[];
  error?: ApolloError;
  formError?: boolean;
  errorText?: string;
  placeholder?: string;
  showResults: boolean;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  onBlur: FocusEventHandler<HTMLSpanElement>;
  onKeyDown: KeyboardEventHandler<HTMLInputElement>;
  onFocus: () => void;
  onSelectUser: (user: getUsersByKeyword_getUsersByKeyword) => void;
  onClickAway: () => void;
};

export const HostSelect = ({
  id,
  loading,
  value,
  users,
  error,
  formError,
  errorText,
  placeholder,
  showResults,
  onSelectUser,
  onFocus,
  onChange,
  onBlur,
  onKeyDown,
  onClickAway,
}: PropTypes): JSX.Element => {
  const containerRef = useRef(null);
  useClickAway(containerRef, onClickAway);
  const { moveThroughMenu, focusedIndex } = useKeyboardNavigation(
    showResults,
    users
  );

  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    onKeyDown(event);

    if (event.key === 'Escape' && showResults) {
      // Close menu on esc
      onClickAway();
      event.stopPropagation();
      return;
    }
    if (event.key === 'Enter' && focusedIndex !== null && users) {
      // Select a user on enter
      onSelectUser(users[focusedIndex]);
      return;
    }
    if (users) {
      // Move up/down through menu items
      moveThroughMenu(event.key);
    }
  };

  return (
    <Container ref={containerRef}>
      <Input
        aria-autocomplete="list"
        aria-expanded={showResults}
        aria-owns={HTML_MENU_ID}
        role="combobox"
        id={id}
        width="100%"
        placeholder={placeholder}
        autoComplete="off"
        value={value}
        onFocus={onFocus}
        onChange={onChange}
        onBlur={onBlur}
        onKeyDown={handleKeyDown}
        errorText={errorText}
        error={formError && !showResults}
        style={{
          fontSize: '26px',
          padding: '20px 18px', // Will get 6px horizontal padding
        }}
        {...undefined}
      />
      {showResults && (
        <HostSelectResults
          id={HTML_MENU_ID}
          loading={loading}
          error={error}
          users={users}
          focusedIndex={focusedIndex}
          onSelectUser={onSelectUser}
        />
      )}
    </Container>
  );
};

const Container = styled.div`
  border-radius: 8px;
  position: relative;
  width: 100%;
`;
