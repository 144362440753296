import styled from '@emotion/styled/macro';
import {
  Button,
  Heading01,
  Body05,
  Dialog,
  Colors,
} from '@robinpowered/design-system';
import { createPortal } from 'react-dom';
import { useTranslation } from 'react-i18next';
import MinorWarning from '@robinpowered/icons/v2/MinorWarningSolid';

type PropTypes = {
  onPress: () => void;
};

export const UnauthorizedErrorModal = ({
  onPress,
}: PropTypes): JSX.Element | null => {
  const { t } = useTranslation('unauthorizedModal');

  return createPortal(
    <>
      <Dialog.Overlay style={{ backgroundColor: 'rgba(0,0,0,0.3)' }} />
      <Dialog style={{ minWidth: '425px' }} {...undefined}>
        <Heading01
          mb={3}
          style={{
            display: 'flex',
            alignItems: 'center',
            fontSize: '22px',
          }}
        >
          <MinorWarning
            size={24}
            color={Colors.RedProduct}
            style={{ marginRight: '4px' }}
          />
          {t('heading')}
        </Heading01>
        <Body05 color={Colors.Gray40} mb={3} mr={2}>
          {t('body')}
        </Body05>
        <PrimaryButton
          aria-label={t('done')}
          variant="primary"
          onClick={() => onPress()}
        >
          {t('done')}
        </PrimaryButton>
      </Dialog>
    </>,
    document.body
  );
};

const PrimaryButton = styled(Button)`
  margin-left: auto;
`;
