import styled from '@emotion/styled/macro';
import { Colors, Heading01, Table } from '@robinpowered/design-system';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import { ContentWrapper, Header, Section } from 'components';
import { formatStartTime } from 'utils';
import { GetVisitsByEmailQuery_getVisitsByEmail } from 'hooks/__generated__/GetVisitsByEmailQuery';
import { usePageLoadedEvent } from 'contexts';
import { GuestInviteStatus } from '__generated__/globalTypes';
import { useTimedRedirect } from 'hooks';

type LocationState = {
  visits: GetVisitsByEmailQuery_getVisitsByEmail[];
};

const padTimeWithSpace = (time: string) => {
  // Make all times have the same number of characters
  // " 1:00 pm" and "12:00 pm" vs "1:00 pm"
  return time.length === 7 ? ` ${time}` : time;
};

export const VisitSelection = (): JSX.Element => {
  const { t } = useTranslation('visitSelection');
  const history = useHistory();
  const { state } = useLocation<LocationState>();
  const { visits } = state;
  usePageLoadedEvent('select-visit', false);
  useTimedRedirect();

  return (
    <>
      <Header
        nextDisabled={true}
        onPrevClick={() => {
          history.push('/guest-lookup');
        }}
        onNextClick={() => {}}
      />
      <ContentWrapper>
        <Heading01 mb="4" id="radioLabel">
          {t('heading')}
        </Heading01>

        <Section boxed role="radiogroup" aria-labelledby="radioLabel">
          <Table columnWidths={['90px']}>
            <thead>
              <Table.Tr>
                <Table.Th>{t('time')}</Table.Th>
                <Table.Th>{t('host')}</Table.Th>
              </Table.Tr>
            </thead>
            <tbody>
              {visits.map((visit: GetVisitsByEmailQuery_getVisitsByEmail) => {
                const nextPath =
                  visit.status === GuestInviteStatus.CHECKED_IN ||
                  visit.status === GuestInviteStatus.CHECKED_OUT
                    ? `/${visit.id}/completed`
                    : `/${visit.id}/visit-details`;
                const visitTime = formatStartTime(
                  visit.startTime,
                  visit.arrivalLocation.timezone
                );
                const ariaLabel = visitTime + ' ' + visit.host.name;
                return (
                  <Table.Tr key={visit.id}>
                    <TableData
                      aria-label={ariaLabel}
                      style={{
                        whiteSpace: 'pre', // Allow our whitespace padding
                        color: Colors.Gray40,
                        padding: '20px 32px 20px 16px',
                      }}
                      onClick={() => history.push(nextPath)}
                    >
                      {padTimeWithSpace(visitTime)}
                    </TableData>
                    <TableData
                      aria-label={ariaLabel}
                      style={{ padding: '20px 16px' }}
                      onClick={() => history.push(nextPath)}
                    >
                      {visit.host.name}
                    </TableData>
                  </Table.Tr>
                );
              })}
            </tbody>
          </Table>
        </Section>
      </ContentWrapper>
    </>
  );
};

const TableData = styled(Table.Td)`
  /* TODO: Spread the font style from the design system once we export that */
  color: ${Colors.Gray100};
  font-size: 16px;
  font-size: 20px;
  line-height: 32px;

  :hover {
    color: initial; /* Undo blue highlighting */
  }
`;
