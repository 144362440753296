import styled from '@emotion/styled/macro';
import {
  Button,
  Heading01,
  Body01,
  Colors,
  Row,
} from '@robinpowered/design-system';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { ContentWrapper } from 'components';
import graphic from 'media/error-device-graphic.png';
import { Header } from 'components';

export const RegistrationServerError = ({
  onPressRetry,
}: {
  onPressRetry: () => unknown;
}): JSX.Element => {
  const { t } = useTranslation('guestRegistrationHcpLoading');
  const history = useHistory();

  return (
    <>
      <Header
        backHidden
        nextHidden
        onNextClick={() => {}}
        onPrevClick={() => {}}
      />
      <ContentWrapper
        boxStyle={{ backgroundColor: Colors.Tan5, boxShadow: 'none' }}
      >
        <Graphic src={graphic} />
        <Heading01
          mb={3}
          style={{
            fontSize: '26px',
            lineHeight: '36px',
            textAlign: 'center',
          }}
        >
          {t('server_error.heading')}
        </Heading01>
        <Body01 mb={4} style={{ lineHeight: '41px', textAlign: 'center' }}>
          {t('server_error.body')}
        </Body01>
        <Row display="flex" justifyContent="center">
          <StyledButton
            mr="4"
            aria-label={t('server_error.try_again')}
            variant="primary"
            onClick={() => onPressRetry()}
          >
            {t('server_error.try_again')}
          </StyledButton>
          <StyledLink
            aria-label={t('server_error.go_home')}
            onClick={() => history.push('/')}
          >
            {t('server_error.go_home')}
          </StyledLink>
        </Row>
      </ContentWrapper>
    </>
  );
};

const Graphic = styled.img`
  margin-bottom: 95px;
  max-width: 100%;
`;

const StyledButton = styled(Button)`
  box-shadow: none;
  border-radius: 16px;
  padding: 16px 40px;
  font-size: 22px;
  line-height: 28px;
  height: auto;
`;

const StyledLink = styled(Button)`
  box-shadow: none;
  border: none;
  background-color: transparent;
  color: ${Colors.Magenta100};
  padding: 16px 40px;
  font-size: 22px;
  line-height: 28px;
  height: auto;

  &:hover {
    box-shadow: none;
    border: none;
    background-color: transparent;
    color: ${Colors.Magenta100};
    padding: 16px 40px;
    font-size: 22px;
    line-height: 28px;
    height: auto;
  }
`;
