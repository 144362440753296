import styled from '@emotion/styled/macro';
import { CenteredContent, Footer, LoaderLarge } from 'components';

export const AppLoader = (): JSX.Element => (
  <CenteredContent>
    <MainContent>
      <LoaderLarge />
    </MainContent>
    <Footer />
  </CenteredContent>
);

const MainContent = styled.section`
  flex: 1;
  padding: 25% 66px 100px;
`;
