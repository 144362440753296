import { useEffect, useState, useCallback, useRef } from 'react';
import styled from '@emotion/styled/macro';
import {
  SpinnerLoader,
  Body02,
  Colors,
  Column,
} from '@robinpowered/design-system';
import moment from 'moment-timezone';
import { useTranslation } from 'react-i18next';
import { useSearchVisits } from './hooks/useSearchVisits';
import { VisitRow } from './VisitRow';
import { ErrorRow } from './ErrorRow';
import { useMinimumLoading } from 'hooks';
import { useDeviceContext } from 'contexts';
import { useDebounce } from 'react-use';
import { GetVisitsByPartialEmailQuery_getCheckedInVisitsByPartialEmail as Visit } from './hooks/__generated__/GetVisitsByPartialEmailQuery';
import { useCheckOutContext } from './CheckOutContext';

enum ScreenState {
  INITIAL_STATE,
  NOT_ENOUGH_CHARACTERS,
  LOADING,
  ERROR,
  NO_RESULTS,
  SUCCESS,
}

const SEARCH_DEBOUNCE_MILLIS = 500; // Half a second
const MAX_RESULTS = 10;

export const VisitResults = ({
  emailSearchString,
  style,
}: {
  emailSearchString: string;
  style: React.CSSProperties;
}): JSX.Element => {
  const { t } = useTranslation('checkOut');
  const { location } = useDeviceContext();
  const { checkOutMutation } = useCheckOutContext();
  const abortController = useRef<AbortController>();
  const [screenState, _setScreenState] = useState(ScreenState.INITIAL_STATE);
  const [visitResults, setVisitResults] = useState<Visit[] | undefined | null>(
    null
  );
  const setScreenState = useCallback(
    (state: ScreenState) => {
      if (screenState !== state) _setScreenState(state);
    },
    [screenState, _setScreenState]
  );

  const [searchForVisits, { data, loading: _loading, error, refetch }] =
    useSearchVisits();
  const loading = useMinimumLoading(_loading);
  const retrySearch = () => {
    if (!refetch) return;
    refetch();
  };

  // When the search string changes, trigger a debounced search
  useDebounce(
    () => {
      if (emailSearchString.length < 3) return;
      const controller = new window.AbortController();
      abortController.current = controller;
      searchForVisits({
        variables: {
          email: emailSearchString,
          minutesBefore: moment().diff(moment().startOf('d'), 'm'), // Start of today
          minutesAfter: moment().endOf('d').diff(moment(), 'm'), // End of today
          locationId: location?.id,
          resultLimiter: MAX_RESULTS,
        },
        context: {
          fetchOptions: {
            signal: controller.signal,
          },
        },
      });
    },
    SEARCH_DEBOUNCE_MILLIS,
    [emailSearchString]
  );

  useEffect(() => {
    setVisitResults(
      data?.getCheckedInVisitsByPartialEmail?.slice(0, MAX_RESULTS)
    ); // Limit results to 10
  }, [data?.getCheckedInVisitsByPartialEmail]);

  // When the search string changes, we need to clear the previous results
  // and show a loading UI until the debounce expires and we next fetch results.
  useEffect(() => {
    setVisitResults(null);
    // Abort any in-progress search query: https://evilmartians.com/chronicles/aborting-queries-and-mutations-in-react-apollo
    abortController.current && abortController.current.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [emailSearchString]);

  useEffect(() => {
    if (emailSearchString.length < 3) {
      setScreenState(ScreenState.NOT_ENOUGH_CHARACTERS);
    } else if (loading || !visitResults) {
      setScreenState(ScreenState.LOADING);
    } else if (error) {
      setScreenState(ScreenState.ERROR);
    } else if (visitResults?.length === 0) {
      setScreenState(ScreenState.NO_RESULTS);
    } else {
      setScreenState(ScreenState.SUCCESS);
    }
  }, [loading, error, emailSearchString, visitResults, setScreenState]);

  return (
    <ResultsContainer style={style}>
      {screenState === ScreenState.NOT_ENOUGH_CHARACTERS && (
        <Centered>
          <Body02>{t('min_characters_message')}</Body02>
        </Centered>
      )}
      {screenState === ScreenState.NO_RESULTS && (
        <Centered>
          <Body02>{t('no_results_message')}</Body02>
        </Centered>
      )}
      {(screenState === ScreenState.LOADING ||
        screenState === ScreenState.INITIAL_STATE) && (
        <Centered>
          <SpinnerLoader size={24} />
        </Centered>
      )}
      {screenState === ScreenState.ERROR && (
        <ErrorRow onPressRetry={retrySearch} />
      )}
      {screenState === ScreenState.SUCCESS && (
        <Column flex={1} paddingBottom="20px">
          {(visitResults ?? []).map((visit) => (
            <VisitRow
              key={visit.id}
              visit={visit}
              onPressCheckOut={() => {
                checkOutMutation({
                  variables: {
                    guestInviteId: visit.id,
                  },
                });
              }}
            />
          ))}
        </Column>
      )}
    </ResultsContainer>
  );
};

const ResultsContainer = styled.div`
  min-height: 86px;
  max-height: 300px; /* Fit in horizontal displays */
  overflow-y: auto; /* Allow contents to scroll */
  border-radius: 8px;
  border: 1px solid ${Colors.Tan70};
  box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.15), 0px 2px 12px rgba(0, 0, 0, 0.06);
  display: flex;
`;

// Don't center within ResultsContainer - causes issues when VisitRow needs to scroll
// https://stackoverflow.com/a/33455342/3900967
const Centered = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
`;
