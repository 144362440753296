import { createContext, ReactNode, useContext, useState } from 'react';

export type BadgePrintingContextValue = {
  printerIpAddress: string | null;
  setPrinterIpAddress: (ip: string | null) => void;
};

const BadgePrintingContext = createContext<
  BadgePrintingContextValue | undefined
>(undefined);

export const BadgePrintingContextProvider = ({
  children,
}: {
  children: ReactNode;
}): JSX.Element => {
  const [printerIpAddress, setPrinterIpAddress] = useState<string | null>(null);

  return (
    <BadgePrintingContext.Provider
      value={{
        printerIpAddress,
        setPrinterIpAddress,
      }}
    >
      {children}
    </BadgePrintingContext.Provider>
  );
};

export function useBadgePrintingContext(): BadgePrintingContextValue {
  const context = useContext(BadgePrintingContext);
  if (!context) {
    throw new Error(
      'useBadgePrintingContext must be used within a BadgePrintingContextProvider'
    );
  }
  return context;
}
