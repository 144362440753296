import {
  Colors,
  Column,
  Row,
  Avatar,
  Body05,
} from '@robinpowered/design-system';
import { getUsersByKeyword_getUsersByKeyword } from '../HostSelect';
import { ClearButton } from './ClearButton';

type PropTypes = {
  host: getUsersByKeyword_getUsersByKeyword;
  onClear: () => void;
};

export const SelectedHost = ({ host, onClear }: PropTypes): JSX.Element => {
  return (
    <Row
      alignItems="center"
      flex="1"
      backgroundColor={Colors.Tan5}
      padding="3"
      borderRadius="8px"
      overflow="hidden"
    >
      <Column mr="16px">
        <Avatar name={host.name ?? ''} src={host.avatar ?? ''} size={40} />
      </Column>
      <Column flex="1" overflow="hidden">
        <Body05
          style={{ color: Colors.Gray100, fontSize: 26, lineHeight: '24px' }}
        >
          {host.name}
        </Body05>
      </Column>
      <Column>
        <ClearButton onClick={onClear} />
      </Column>
    </Row>
  );
};
