import styled from '@emotion/styled/macro';
import {
  Button,
  Heading01,
  Body06,
  Dialog,
  Colors,
  Row,
} from '@robinpowered/design-system';
import { createPortal } from 'react-dom';
import { useTranslation } from 'react-i18next';

type PropTypes = {
  onCancel: () => void;
  onPress: () => void;
};

export const BadgeReprintModal = ({
  onCancel,
  onPress,
}: PropTypes): JSX.Element | null => {
  const { t } = useTranslation('checkInCompleted');

  return createPortal(
    <>
      <Dialog.Overlay style={{ backgroundColor: 'rgba(0,0,0,0.3)' }} />
      <Dialog style={{ minWidth: '425px', padding: 0 }} {...undefined}>
        <HeadingWrapper>
          <Heading01
            mb={3}
            style={{
              display: 'flex',
              alignItems: 'center',
              fontSize: '22px',
            }}
          >
            {t('badge_print_modal.heading')}
          </Heading01>
          <Body06 mb={3} style={{ lineHeight: 1.6 }}>
            {t('badge_print_modal.body')}
          </Body06>
        </HeadingWrapper>
        <StyledRow>
          <PrimaryButton
            aria-label={t('badge_print_modal.cancel')}
            variant="secondary"
            onClick={() => onCancel()}
            mr="16px"
          >
            {t('badge_print_modal.cancel')}
          </PrimaryButton>
          <PrimaryButton
            aria-label={t('badge_print_modal.reprint')}
            variant="primary"
            onClick={() => onPress()}
          >
            {t('badge_print_modal.reprint')}
          </PrimaryButton>
        </StyledRow>
      </Dialog>
    </>,
    document.body
  );
};

const PrimaryButton = styled(Button)`
  border-radius: 2px;
`;

const StyledRow = styled(Row)`
  border-top: 1px solid ${Colors.Gray10};
  justify-content: flex-end;
  padding: 12px 18px;
`;

const HeadingWrapper = styled.div`
  padding: 18px;
`;
