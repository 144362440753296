import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { ArrivalDisplayButton, Heading00 } from 'components';

const AUTO_CLOSE_TIMEOUT = 1000 * 10; // 10 seconds

type CheckoutCompleteArgs = {
  onPressClose: () => void;
};

export const CheckoutComplete: React.FC<CheckoutCompleteArgs> = ({
  onPressClose,
}) => {
  const { t } = useTranslation('checkOut');

  useEffect(() => {
    const timerId = setTimeout(onPressClose, AUTO_CLOSE_TIMEOUT);
    return () => clearTimeout(timerId);
  }, [onPressClose]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <Heading00 style={{ fontSize: '30px' }} mb="24px">
        {t('checkout_success_message')}
      </Heading00>
      <ArrivalDisplayButton
        style={{
          marginLeft: 'auto',
        }}
        onClick={onPressClose}
      >
        {t('okay')}
      </ArrivalDisplayButton>
    </>
  );
};
