import { Box, Colors } from '@robinpowered/design-system';
import styled from '@emotion/styled/macro';
import { ReactNode } from 'react';

type PropTypes = {
  children: ReactNode;
  backgroundColor?: string;
};

export const PageSection = ({
  backgroundColor,
  children,
}: PropTypes): JSX.Element => (
  <Box padding="4" backgroundColor={backgroundColor}>
    <CenteredContent>{children}</CenteredContent>
  </Box>
);

const CenteredContent = styled.div`
  background-color: ${Colors.Tan5};
  max-width: 560px;
  width: 100%;
  margin: 0 auto;
`;
