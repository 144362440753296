import React from 'react';
import { Button, Colors, Skeleton } from '@robinpowered/design-system';
import styled from '@emotion/styled/macro';
import { useTranslation } from 'react-i18next';
import { CompanyLogo } from './CompanyLogo';
import { useDeviceContext } from 'contexts';

type ButtonClick = () => void;

type PropTypes = {
  isLoading?: boolean;
  prevButtonText?: string;
  nextButtonText?: string;
  nextDisabled?: boolean;
  backDisabled?: boolean;
  nextHidden?: boolean;
  backHidden?: boolean;
  onNextClick: ButtonClick;
  onPrevClick: ButtonClick;
};

const _Header = (
  {
    isLoading,
    prevButtonText,
    nextButtonText,
    nextDisabled = false,
    backDisabled = false,
    nextHidden = false,
    backHidden = false,
    onNextClick,
    onPrevClick,
  }: PropTypes,
  ref: React.LegacyRef<HTMLElement>
): JSX.Element => {
  const { t } = useTranslation('common');
  const { org } = useDeviceContext();

  return (
    <HeaderWrapper ref={ref}>
      <HeaderContent>
        <NavigationButton
          disabled={backDisabled}
          style={backHidden ? { visibility: 'hidden' } : undefined}
          variant="secondary"
          name={t('navigation.back')}
          onClick={onPrevClick}
        >
          {prevButtonText || t('navigation.back')}
        </NavigationButton>
        {!org ? (
          <Skeleton width={125} height={48} />
        ) : org?.logo && org.name ? (
          <CompanyLogo companyName={org.name} imageSource={org.logo} />
        ) : null}
        <NavigationButton
          disabled={nextDisabled}
          style={nextHidden ? { visibility: 'hidden' } : undefined}
          isLoading={isLoading}
          variant="primary"
          name={t('navigation.next')}
          onClick={onNextClick}
        >
          {nextButtonText || t('navigation.next')}
        </NavigationButton>
      </HeaderContent>
    </HeaderWrapper>
  );
};

export const Header = React.forwardRef(_Header);

const HeaderWrapper = styled.header`
  background-color: ${Colors.White0};
`;

const HeaderContent = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  padding: 24px;
`;

const NavigationButton = styled(Button)`
  border-radius: 16px;
  height: auto;
  font-size: 22px;
  padding: 18px 54px;
`;
