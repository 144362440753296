import {
  createContext,
  ReactNode,
  useContext,
  useState,
  useMemo,
  useEffect,
} from 'react';
import { GET_VISIT_BY_ID } from 'hooks/useVisitDetails';
import {
  GetGuestVisitById,
  GetGuestVisitByIdVariables,
  GetGuestVisitById_getGuestVisitById,
} from 'hooks/__generated__/GetGuestVisitById';
import { useLazyQuery, LazyQueryResult } from '@apollo/client';

export enum HealthCheckpointStatus {
  UNKNOWN = 'UNKNOWN', // Can be used before the survey has been taken
  FAILED = 'FAILED',
  PASSED = 'PASSED',
}

type VisitContextValue = Pick<
  LazyQueryResult<GetGuestVisitById, GetGuestVisitByIdVariables>,
  'loading' | 'error' | 'refetch'
> & {
  visit: GetGuestVisitById_getGuestVisitById | null;
  setVisitId: (visitId: string) => void;
  healthCheckpointStatus: HealthCheckpointStatus;
  setHealthCheckpointStatus: (arg0: HealthCheckpointStatus) => void;
};

type VisitContextProps = {
  /** Seeds the first value of the visitId. Use `setVisitId` if the value might change. */
  initialVisitId?: string;
  children: ReactNode;
};

const VisitContext = createContext<VisitContextValue | undefined>(undefined);

export const VisitContextProvider = ({
  initialVisitId,
  children,
}: VisitContextProps): JSX.Element => {
  const [healthCheckpointStatus, setHealthCheckpointStatus] = useState(
    HealthCheckpointStatus.UNKNOWN
  );
  const [visitId, setVisitId] = useState(initialVisitId);
  const [getVisit, { data, loading, error, refetch }] = useLazyQuery<
    GetGuestVisitById,
    GetGuestVisitByIdVariables
  >(GET_VISIT_BY_ID, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'cache-and-network',
  });
  useEffect(() => {
    if (visitId) {
      getVisit({
        variables: {
          guestVisitId: visitId,
        },
      });
    }
  }, [getVisit, visitId]);

  const contextValue = useMemo(
    () => ({
      loading,
      error,
      refetch,
      visit: data?.getGuestVisitById ?? null,
      setVisitId,
      healthCheckpointStatus,
      setHealthCheckpointStatus,
    }),
    [
      loading,
      error,
      refetch,
      data?.getGuestVisitById,
      setVisitId,
      healthCheckpointStatus,
      setHealthCheckpointStatus,
    ]
  );

  return (
    <VisitContext.Provider value={contextValue}>
      {children}
    </VisitContext.Provider>
  );
};

export function useVisitContext(): VisitContextValue {
  const context = useContext(VisitContext);
  if (!context) {
    throw new Error(
      'useVisitContext must be used within a VisitContextProvider'
    );
  }
  return context;
}
