import { gql } from 'graphql-tag';
import {
  useMutation,
  MutationTuple,
  MutationHookOptions,
} from '@apollo/client';
import {
  RegisterGuestWithoutHCPFromArrivalDisplayWithCustomVisitType,
  RegisterGuestWithoutHCPFromArrivalDisplayWithCustomVisitTypeVariables,
} from './__generated__/RegisterGuestWithoutHCPFromArrivalDisplayWithCustomVisitType';

const REGISTER_WITHOUT_HCP_MUTATION = gql`
  mutation RegisterGuestWithoutHCPFromArrivalDisplayWithCustomVisitType(
    $guestName: String!
    $guestEmail: String!
    $startTime: ISODateTime!
    $arrivalLocationId: ID!
    $hostUserId: ID!
    $visitType: String!
    $visitId: ID!
    $visitInviteId: ID!
  ) {
    registerGuestWithoutHCPFromArrivalDisplayWithCustomVisitType(
      guestName: $guestName
      guestEmail: $guestEmail
      startTime: $startTime
      arrivalLocationId: $arrivalLocationId
      hostUserId: $hostUserId
      visitType: $visitType
      visitId: $visitId
      visitInviteId: $visitInviteId
    )
  }
`;

/**
 * Registers a guest (creates a visit) for buildings without health checkpoints.
 *
 * This should be used only in buildings where HCPs are NOT enabled.
 * If HCPs are enabled for the building, use useRegisterWithHcpMutation instead.
 *
 * A successful response means a visit has been created.
 */
export const useRegisterWithoutHcpMutation = (
  options?: MutationHookOptions<
    RegisterGuestWithoutHCPFromArrivalDisplayWithCustomVisitType,
    RegisterGuestWithoutHCPFromArrivalDisplayWithCustomVisitTypeVariables
  >
): MutationTuple<
  RegisterGuestWithoutHCPFromArrivalDisplayWithCustomVisitType,
  RegisterGuestWithoutHCPFromArrivalDisplayWithCustomVisitTypeVariables
> => {
  return useMutation<
    RegisterGuestWithoutHCPFromArrivalDisplayWithCustomVisitType,
    RegisterGuestWithoutHCPFromArrivalDisplayWithCustomVisitTypeVariables
  >(REGISTER_WITHOUT_HCP_MUTATION, options);
};
