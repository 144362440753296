import { useEffect } from 'react';

const shouldQueueReload = (): boolean => {
  const now = new Date();
  return (
    now.getHours() === 0 && now.getMinutes() === 0 && now.getSeconds() === 0
  );
};

/**
 * Hook to automatically trigger a page reload sometime between midnight
 * and 1am every day. This is a naive way to ensure devices pick up new
 * deployments since devices are "always on".
 */
export const useAutomaticPageReload = (): void => {
  useEffect(() => {
    let refreshTimerId: NodeJS.Timeout;

    const refreshAfterDelay = () => {
      // Delay the refresh from anywhere between 0 and 60 minutes.
      // This prevents every display from refreshing at the exact same time.
      const drift = Math.floor(Math.random() * 60) * 1000;
      refreshTimerId = setTimeout(() => {
        window.location.reload();
      }, drift);
    };

    const queueRefreshTimerId = setInterval(() => {
      if (shouldQueueReload()) {
        clearInterval(queueRefreshTimerId);
        refreshAfterDelay();
      }
    }, 100);

    return () => {
      clearInterval(queueRefreshTimerId);
      clearTimeout(refreshTimerId);
    };
  }, []);
};
