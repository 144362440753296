import { Skeleton } from '@robinpowered/design-system';

export const LoaderLarge = (): JSX.Element => {
  return (
    <>
      <Skeleton width={280} height={50} marginBottom={48} />
      <Skeleton width={585} height={95} marginBottom={24} />
      <Skeleton width={470} height={95} marginBottom={48} />
      <Skeleton width={245} height={72} marginBottom={24} />
      <Skeleton width={245} height={72} marginBottom={24} />
    </>
  );
};
