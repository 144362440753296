import { Body04, Heading01 } from '@robinpowered/design-system';
import { BoundedErrorBadge, ContentWrapper, Header } from 'components';
import {
  useApolloContext,
  useBadgePrintingContext,
  useVisitContext,
} from 'contexts';
import { useHistory } from 'react-router';
import { useCheckInPreRegVisit, useTimedRedirect } from 'hooks';
import { useTranslation } from 'react-i18next';
import { GuestInviteStatus } from '__generated__/globalTypes';
import { useEffect } from 'react';

const REDIRECT_SECONDS = 1000 * 5; // 5 seconds

export const CheckIn = (): JSX.Element => {
  const { t } = useTranslation('healthCheckpoint');
  const history = useHistory();
  const { printerIpAddress } = useBadgePrintingContext();
  const { bearerToken, tenantId } = useApolloContext();
  const { visit } = useVisitContext();
  const { status, id, visitId } = visit ?? {};
  const isCheckedIn = status === GuestInviteStatus.CHECKED_IN;
  const isCheckedOut = status === GuestInviteStatus.CHECKED_OUT;
  const { checkIn, error: checkInError } = useCheckInPreRegVisit();
  const pdfEndpoint =
    process.env.REACT_APP_PDF_URL ?? 'https://badges.robinpowered.com/pdf';

  /**
   * Calls the checkin mutation for visits that have not been checked-in, and
   * transitions the user to the completed screen. Brings the user directly to
   * the completed screen if the visit is already marked as checked-in.
   */
  const completeCheckIn = () => {
    if (id) {
      if (!isCheckedIn && !isCheckedOut) {
        checkIn(id).then(() => {
          if (printerIpAddress) printBadge();
          setTimeout(() => history.push(`/${id}/completed`), REDIRECT_SECONDS);
        });
      } else {
        history.push(`/${id}/completed`);
      }
    } else {
      history.push(`/completed`);
    }
  };

  /**
   * Posts a message to the window using the postMessage API. The native application
   * wrapping this will be listening for this message and will use it to print the badge.
   */
  const printBadge = () => {
    if (window?.webkit?.messageHandlers?.printAction) {
      window.webkit.messageHandlers.printAction.postMessage(
        {
          visitId: visitId,
          printerIp: printerIpAddress,
          jwt: bearerToken,
          pdfEndpoint: pdfEndpoint,
          tenantId: tenantId,
          guestInviteId: id,
        },
        '*'
      );
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(completeCheckIn, []);

  useTimedRedirect();

  return (
    <>
      <Header
        backHidden
        nextDisabled={!checkInError}
        isLoading={!checkInError}
        onPrevClick={() => {}}
        onNextClick={completeCheckIn}
      />

      {checkInError && <BoundedErrorBadge message={t('check_in_error')} />}

      <ContentWrapper style={{ textAlign: 'center' }}>
        {!checkInError &&
          (printerIpAddress ? (
            <>
              <Heading01
                role="alert"
                mb="16px"
                style={{ fontSize: '26px', lineHeight: '36px' }}
              >
                {t('survey_passed.badge_printing_loading_message')}
              </Heading01>
              <Body04 style={{ fontSize: '16px', display: 'block' }}>
                {t('survey_passed.badge_printing_loading_sub_message')}
              </Body04>
            </>
          ) : (
            <Heading01
              role="alert"
              style={{ fontSize: '26px', lineHeight: '36px' }}
            >
              {t('survey_passed.loading_message')}
            </Heading01>
          ))}
      </ContentWrapper>
    </>
  );
};
