import { useEffect, useState } from 'react';

type UseKeyboardNavigation = {
  moveThroughMenu: (key: string) => void;
  focusedIndex: number | null;
};

export const useKeyboardNavigation = (
  menuOpen: boolean,
  menuItems: unknown[] | null
): UseKeyboardNavigation => {
  const [focusedIndex, setFocusedIndex] = useState<number | null>(null);
  useEffect(() => {
    if (!menuOpen) {
      // Reset the focused item on each open
      setFocusedIndex(null);
    }
  }, [menuOpen]);

  const moveThroughMenu = (key: string) => {
    if (key === 'ArrowDown') {
      setFocusedIndex((index) => {
        if (index === null) {
          // Bring focus into the menu
          return 0;
        }
        if (menuItems && index === menuItems.length - 1) {
          // Loop back to the beginning
          return 0;
        }
        return index + 1;
      });
    } else if (key === 'ArrowUp') {
      setFocusedIndex((index) => {
        if (index === null) {
          // Bring focus into the menu
          return menuItems?.length ?? 0 - 1;
        }
        if (index === 0) {
          // Loop back around
          return menuItems?.length ?? 0 - 1;
        }
        return index - 1;
      });
    }
  };

  return {
    moveThroughMenu,
    focusedIndex,
  };
};
