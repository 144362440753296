import { ReactNode, useContext, useMemo, createContext } from 'react';
import {
  ApolloProvider as ApolloClientProvider,
  ApolloClient,
  from,
} from '@apollo/client';
import { InMemoryCache } from '@apollo/client/cache/';
import { createHttpLink } from '@apollo/client/link/http';
import { onError } from '@apollo/client/link/error';
import { setContext } from '@apollo/client/link/context';
import { useLocalStorage } from 'react-use';
import useCookie from 'react-use-cookie';
import { Severity } from '@sentry/types';
import { Sentry } from 'lib/sentry';
import { certObfuscation } from 'constants/certObfuscation';
import { useTokenStorage } from 'hooks';

const httpLink = createHttpLink({
  uri: process.env.REACT_APP_GQL_URL ?? '',
});

const cache = new InMemoryCache();

const LOCAL_STORAGE_TENANT_KEY = process.env.REACT_APP_TENANT_ID_STORAGE_KEY;
if (!LOCAL_STORAGE_TENANT_KEY) {
  throw new Error('LOCAL_STORAGE_TENANT_KEY env var not set');
}

const COOKIE_CERT_KEY = process.env.REACT_APP_CERT_KEY;
if (!COOKIE_CERT_KEY) {
  throw new Error('COOKIE_CERT_KEY env var not set');
}

type ApolloContextValue = {
  refreshToken: string | undefined;
  bearerToken: string | undefined;
  certKey: string | null;
  tenantId: string | undefined;
  setAuthTokens: (bearerToken: string, refreshToken: string) => void;
  setTenantId: (tenantId: string) => void;
  setCertKey: (certKey: string, options?: { [key: string]: unknown }) => void;
  clearAuthTokens: () => void;
};

const ApolloContext = createContext<ApolloContextValue | undefined>(undefined);

export const ApolloProvider = ({
  children,
}: {
  children: ReactNode;
}): JSX.Element => {
  const {
    bearerToken,
    setBearerToken,
    removeBearerToken,
    refreshToken,
    setRefreshToken,
    removeRefreshToken,
  } = useTokenStorage();
  const [tenantId, setTenantId] = useLocalStorage<string | undefined>(
    LOCAL_STORAGE_TENANT_KEY
  );
  const [certKey, setCertKeyCookie] = useCookie(COOKIE_CERT_KEY);

  const setAuthTokens = (bearerToken: string, refreshToken: string) => {
    setBearerToken(bearerToken);
    setRefreshToken(refreshToken);
  };

  const clearAuthTokens = () => {
    removeBearerToken();
    removeRefreshToken();
  };

  /**
   * Allows us to set a cookie with the certificate used to sign a JWT. Adds
   * some minor obfuscation and a large TTL for the stored cookie.
   */
  const setCertKey = (cert: string) => {
    setCertKeyCookie(`${cert}${certObfuscation}`, { days: 365 });
  };

  const client = useMemo(() => {
    const authLink = setContext((_, { headers: _headers }) => {
      const headers = {
        ..._headers,
        authorization: bearerToken ? `Bearer ${bearerToken}` : '',
      };
      if (tenantId) {
        headers['tenant-id'] = tenantId;
      }
      return {
        headers,
      };
    });

    const errorLink = onError(
      ({ graphQLErrors, networkError, operation, response }) => {
        const captureException = (
          message: string,
          level: Severity,
          extra?: Record<string, unknown>
        ) => {
          Sentry.withScope((scope) => {
            scope.setLevel(level);
            Sentry.captureException(message, {
              tags: {
                graphql: true,
              },
              extra: {
                ...extra,
                operation,
                response,
              },
            });
          });
        };

        if (graphQLErrors) {
          graphQLErrors.forEach(({ message, locations, path }) => {
            captureException(
              `[GraphQL error]: Message: ${message}`,
              Sentry.Severity.Error,
              {
                path,
                locations,
              }
            );
          });
        }

        if (networkError) {
          captureException(
            `[Network error]: ${networkError}`,
            Sentry.Severity.Info
          );
        }
      }
    );

    return new ApolloClient({
      link: from([errorLink, authLink, httpLink]),
      name: 'arrival-display-ui',
      cache,
      credentials: 'include',
      resolvers: {},
      defaultOptions: {
        mutate: {
          fetchPolicy: 'no-cache',
        },
      },
    });
  }, [bearerToken, tenantId]);

  const value = {
    refreshToken,
    bearerToken,
    certKey,
    tenantId,
    setAuthTokens,
    setTenantId,
    setCertKey,
    clearAuthTokens,
  };

  return (
    <ApolloContext.Provider value={value}>
      <ApolloClientProvider client={client}>{children}</ApolloClientProvider>
    </ApolloContext.Provider>
  );
};

export function useApolloContext(): ApolloContextValue {
  const context = useContext(ApolloContext);
  if (!context) {
    throw new Error('useApolloContext must be used within a ApolloProvider');
  }
  return context;
}
