import styled from '@emotion/styled/macro';
import { Colors } from '@robinpowered/design-system';

export const InputWrapper = styled.div`
  flex: 1;
  min-width: 0; // necessary for FF

  svg {
    height: 18px;
    width: 18px;
    margin-top: 10px;

    & + span {
      font-size: 16px;
      line-height: 1;
      margin-top: 12px;
    }
  }

  span {
    height: auto;
  }

  label {
    color: ${Colors.Gray90};
    display: block;
    font-size: 22px;
    line-height: 26px;
    margin-bottom: 16px;
  }
`;
