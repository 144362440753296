import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import styled from '@emotion/styled/macro';
import { Header, UnauthedServerError } from 'components';
import {
  usePageLoadedEvent,
  useVisitContext,
  HealthCheckpointStatus,
} from 'contexts';
import { useTranslation } from 'react-i18next';
import { useTimedRedirect, useDocumentDetailsWithCustomVisitType } from 'hooks';
import { GuestInviteStatus } from '__generated__/globalTypes';
import { SurveyCompletionStatus } from 'constants/surveyCompletionStatus';
import { isMessageCompletionStatus } from 'utils';

export const HealthCheckpoint: React.FC = () => {
  const [checkpointCompletionStatus, setCheckpointCompletionStatus] =
    useState<SurveyCompletionStatus | null>(null);
  const HeaderRef = useRef<HTMLDivElement>(null);
  const FooterRef = useRef<HTMLDivElement>(null);
  const history = useHistory();
  const { visit, setHealthCheckpointStatus } = useVisitContext();
  const { arrivalLocation, status, visitId, customVisitType } = visit ?? {};
  const isCheckedIn =
    status === GuestInviteStatus.CHECKED_IN ||
    status === GuestInviteStatus.CHECKED_OUT;
  const surveyId = visit?.healthCheckpointSurveyResponse?.id;
  const surveyURL = `${process.env.REACT_APP_HEALTH_SURVEY_BASE_URL}${surveyId}`;
  const { t } = useTranslation(['common', 'healthCheckpoint']);
  const {
    data,
    error: documentDetailsError,
    refetch: refetchDocumentDetails,
  } = useDocumentDetailsWithCustomVisitType(
    customVisitType ?? '',
    arrivalLocation?.id ?? '',
    visitId ?? ''
  );
  const documentData = data?.visitDocumentWithNextDocumentIdWithCustomVisitType;

  usePageLoadedEvent('health-checkpoint', isCheckedIn);
  useTimedRedirect();

  /**
   * Calls the check-in mutation for visits that have not yet been checked into,
   * and puts the user on the pass/fail user flow. If the user has already checked
   * into the visit, they are brought to the completed screen.
   */
  const completeCheckIn = () => {
    if (visit && !isCheckedIn) {
      if (checkpointCompletionStatus === SurveyCompletionStatus.FAILED) {
        setHealthCheckpointStatus(HealthCheckpointStatus.FAILED);
        history.push('health-checkpoint-checking');
      } else {
        setHealthCheckpointStatus(HealthCheckpointStatus.PASSED);
        history.push(documentData ? 'document-agreement' : 'check-in');
      }
    } else {
      history.push('completed');
    }
  };

  const handleSurveyCompletion = useCallback(
    (windowEvent: MessageEvent) => {
      const messageStringUncased: string =
        typeof windowEvent.data === 'string' ? windowEvent.data || '' : '';
      const messageString = messageStringUncased.toLowerCase();

      if (isMessageCompletionStatus(messageString)) {
        setCheckpointCompletionStatus(messageString);
      }
    },
    [setCheckpointCompletionStatus]
  );

  useEffect(() => {
    window.addEventListener('message', handleSurveyCompletion);
    return () => window.removeEventListener('message', handleSurveyCompletion);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (checkpointCompletionStatus) {
      completeCheckIn();
    }
  }, [checkpointCompletionStatus]); // eslint-disable-line react-hooks/exhaustive-deps

  // The iframe doesn't have the ability to flex or fill the page, resulting in a tiny
  // viewport with a scrollbar inside. We're forced to define fixed dimensions for it.
  const viewportHeight = window.parent.innerHeight;
  const headerHeight = HeaderRef.current?.clientHeight ?? 0;
  const footerHeight = FooterRef.current?.clientHeight ?? 0;
  const screenBodyHeight = viewportHeight - headerHeight - footerHeight;

  if (documentDetailsError) {
    return <UnauthedServerError onPressRetry={refetchDocumentDetails} />;
  }

  return (
    <>
      <Header
        ref={HeaderRef}
        nextHidden
        onPrevClick={() => history.push('visit-details')}
        onNextClick={() => {}}
      />

      {!checkpointCompletionStatus && (
        <FlexedFrame
          height={screenBodyHeight}
          width="100%"
          frameBorder="0"
          src={surveyURL}
          title={t('healthCheckpoint:survey_title')}
        />
      )}
    </>
  );
};

const FlexedFrame = styled.iframe`
  flex: 1;
`;
