import styled from '@emotion/styled/macro';
import { Colors } from '@robinpowered/design-system';

export const MAX_WIDTH = '768px';

export const CenteredContent = styled.div`
  background-color: ${Colors.Tan5};
  display: flex;
  flex-direction: column;
  flex: 1;
  max-width: ${MAX_WIDTH};
  width: 100%;
  margin: 0 auto;
`;
