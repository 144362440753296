import { ApolloError, FetchResult, useMutation } from '@apollo/client';
import gql from 'graphql-tag';
import { useState } from 'react';
import {
  CheckIntoVisit,
  CheckIntoVisitVariables,
} from './__generated__/CheckIntoVisit';

const CHECK_IN_QUERY = gql`
  mutation CheckIntoVisit($guestInviteId: ID!) {
    checkIntoVisitFromArrivalDisplay(guestInviteId: $guestInviteId)
  }
`;

type UseCheckIn = {
  checkIn: (
    guestInviteId: string
  ) => Promise<void | FetchResult<CheckIntoVisit>>;
  loading: boolean;
  error: ApolloError | null;
};

const isAlreadyCheckedInError = (error: unknown): boolean => {
  return (
    error instanceof ApolloError &&
    error.graphQLErrors?.[0]?.extensions?.code === 'ALREADY_CHECKED_IN'
  );
};

/**
 * Checks in a visit that was pre-registered (was created in advance, not on the Arrival Display).
 *
 * For checking in visits created on the Arrival Display, use `useCheckInSelfRegVisit`
 */
export const useCheckInPreRegVisit = (): UseCheckIn => {
  const [error, setError] = useState<ApolloError | null>(null);
  const [checkInMutation, { loading }] = useMutation<
    CheckIntoVisit,
    CheckIntoVisitVariables
  >(CHECK_IN_QUERY);

  return {
    checkIn: (guestInviteId: string) => {
      setError(null);

      return checkInMutation({ variables: { guestInviteId } }).catch((err) => {
        // Silently ignore visits that have already checked in.
        // Keep users on the happy path.
        if (!isAlreadyCheckedInError(err)) {
          setError(err);
          throw err;
        }
      });
    },
    loading,
    error,
  };
};
