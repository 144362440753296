import {
  useState,
  useEffect,
  ChangeEvent,
  useCallback,
  FocusEventHandler,
  KeyboardEvent,
} from 'react';
import { useHostSearch, MIN_KEYWORD_LENGTH } from './useHostSearch';
import { getUsersByKeyword_getUsersByKeyword } from './__generated__/getUsersByKeyword';
import { HostSelect } from './HostSelect';

type PropTypes = {
  id: string;
  placeholder?: string;
  errorText?: string;
  formError?: boolean;
  onSelectUser: (user: getUsersByKeyword_getUsersByKeyword) => void;
  onBlur: FocusEventHandler<HTMLSpanElement>;
};

export const HostSelectConnector = ({
  id,
  placeholder,
  errorText,
  formError,
  onSelectUser,
  onBlur,
}: PropTypes): JSX.Element => {
  const { keyword, setKeyword, users, loading, error } = useHostSearch();
  const [inputLabel, setInputLabel] = useState('');
  const [showResults, setShowResults] = useState(false);

  useEffect(() => {
    setInputLabel(keyword);
  }, [keyword]);

  const shouldShowResultsMenu = useCallback(() => {
    return (
      (!!users || loading || !!error) && keyword.length >= MIN_KEYWORD_LENGTH
    );
  }, [users, loading, error, keyword]);

  useEffect(() => {
    setShowResults(shouldShowResultsMenu());
  }, [loading, keyword]); // eslint-disable-line react-hooks/exhaustive-deps

  const onFocus = () => {
    if (shouldShowResultsMenu()) {
      setShowResults(true);
    }
  };

  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    setKeyword(event.target.value);
  };

  const onClickAway = () => {
    setShowResults(false);
  };

  const onKeyDown = (event: KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Tab') {
      // Close menu when tabbing away
      setShowResults(false);
    } else if (
      event.key === 'ArrowDown' &&
      !showResults &&
      shouldShowResultsMenu()
    ) {
      // Show menu on down arrow if it's hidden and is eligible to be shown
      setShowResults(true);
    }
  };

  const handleSelectUser = (user: getUsersByKeyword_getUsersByKeyword) => {
    onSelectUser(user);
    setShowResults(false);
    setInputLabel(user.name ?? user.primaryEmail?.email ?? '');
  };

  return (
    <HostSelect
      id={id}
      loading={loading}
      value={inputLabel}
      error={error}
      formError={formError}
      errorText={errorText}
      users={users}
      placeholder={placeholder}
      showResults={showResults}
      onFocus={onFocus}
      onChange={onChange}
      onBlur={onBlur}
      onSelectUser={handleSelectUser}
      onKeyDown={onKeyDown}
      onClickAway={onClickAway}
    />
  );
};
