import { useEffect, useState } from 'react';
import { ApolloError, useLazyQuery, useMutation } from '@apollo/client';
import { useAmplitude, useApolloContext } from 'contexts';
import { gql } from 'graphql-tag';
import { decode } from 'jsonwebtoken';
import { generateSignedJwt } from 'utils';
import {
  GetOrganizationQuery,
  GetOrganizationQueryVariables,
  GetOrganizationQuery_getOrganizationById,
} from './__generated__/GetOrganizationQuery';
import {
  RefreshArrivalDeviceToken,
  RefreshArrivalDeviceTokenVariables,
  RefreshArrivalDeviceToken_refreshDeviceAuthorization,
  RefreshArrivalDeviceToken_refreshDeviceAuthorization_location,
} from './__generated__/RefreshArrivalDeviceToken';
import { identify as identifySentry, Sentry } from 'lib/sentry';
import { IsArrivalDisplayPaired } from './__generated__/IsArrivalDisplayPaired';
import { ArrivalDisplayRescueQuery } from './__generated__/ArrivalDisplayRescueQuery';
import { TempArrivalDeviceRefresh } from './__generated__/TempArrivalDeviceRefresh';
import { ArrivalDeviceRefreshRecovery } from './__generated__/ArrivalDeviceRefreshRecovery';

const { REACT_APP_RELEASE_VERSION: RELEASE_VERSION } = process.env;

const REFRESH_TOKEN_INTERVAL_MINS = 1000 * 60 * 2; // 2 minutes
const AUTH_RETRY_DELAY_SECONDS = 1000 * 5; // 5 seconds

type UseAuthentication = {
  loading: boolean;
  error?: Error;
  isAuthenticated: boolean;
  organization: GetOrganizationQuery_getOrganizationById | undefined;
  location:
    | RefreshArrivalDeviceToken_refreshDeviceAuthorization_location
    | undefined;
  deviceName: string | undefined;
  deviceId: string | undefined;
  authenticate: () => Promise<void>;
};

const REFRESH_AUTH_MUTATION = gql`
  mutation RefreshArrivalDeviceToken($refreshToken: String!) {
    refreshDeviceAuthorization(refreshToken: $refreshToken) {
      id
      organizationId
      bearerToken
      refreshToken
      deviceName
      location {
        id
        name
        isHealthCheckpointRequired
      }
    }
  }
`;
// Used to migrate older clients that do not yet have a signing certificate
const TEMP_AUTH_MUTATION = gql`
  mutation TempArrivalDeviceRefresh {
    tempArrivalDisplayAuthMigration {
      id
      certKey
      authorization {
        id
        organizationId
        bearerToken
        refreshToken
        deviceName
        location {
          id
          name
          isHealthCheckpointRequired
        }
      }
    }
  }
`;
// Used to get the latest auth tokens and isPaired status, using a signed JWT
const AUTH_RECOVERY_MUTATION = gql`
  mutation ArrivalDeviceRefreshRecovery {
    arrivalDisplayCertRecovery {
      id
      isPaired
      authorization {
        id
        organizationId
        bearerToken
        refreshToken
        deviceName
        location {
          id
          name
          isHealthCheckpointRequired
        }
      }
    }
  }
`;

export const GET_ORGANIZATION_QUERY = gql`
  query GetOrganizationQuery($organizationId: ID!) {
    getOrganizationById(id: $organizationId) {
      id
      name
      logo
      slug
    }
  }
`;

const IS_PAIRED_QUERY = gql`
  query IsArrivalDisplayPaired {
    isArrivalDisplayPaired
  }
`;

const RESCUE_QUERY = gql`
  query ArrivalDisplayRescueQuery {
    arrivalDisplaySelfRescue
  }
`;

const isAuthError = (error: unknown) => {
  return (
    error instanceof ApolloError &&
    error.graphQLErrors?.[0]?.extensions?.code === 'UNAUTHENTICATED'
  );
};

export const useAuthentication = (): UseAuthentication => {
  const {
    bearerToken,
    refreshToken,
    certKey,
    tenantId,
    setAuthTokens,
    setCertKey,
    setTenantId,
    clearAuthTokens,
  } = useApolloContext();
  const { identify: identifyAmplitude } = useAmplitude();
  const [validationError, setValidationError] = useState<Error | undefined>();
  const [isAutoRefreshing, setIsAutoRefreshing] = useState(false);
  const [lastAuthResponse, setLastAuthResponse] =
    useState<RefreshArrivalDeviceToken_refreshDeviceAuthorization | null>(null);
  const [hasAuthFailed, setHasAuthFailed] = useState(false);
  let willRetry = true;

  // Mutation for handing in a refresh token for a new set of bearer & refresh tokens
  const [authMutation, { data: authData, error: authError }] = useMutation<
    RefreshArrivalDeviceToken,
    RefreshArrivalDeviceTokenVariables
  >(REFRESH_AUTH_MUTATION);

  // Mutation for handing in a refresh token for a new set of bearer & refresh tokens
  const [certRecoveryMutation] = useMutation<ArrivalDeviceRefreshRecovery>(
    AUTH_RECOVERY_MUTATION
  );

  // Temporary mutation for getting a cert key and updated refresh/bearer tokens
  const [tempRefreshMutation] =
    useMutation<TempArrivalDeviceRefresh>(TEMP_AUTH_MUTATION);

  // Query for fetching the organization once we've authenticated
  const [getOrganization, { data: orgData, error: orgError }] = useLazyQuery<
    GetOrganizationQuery,
    GetOrganizationQueryVariables
  >(GET_ORGANIZATION_QUERY);

  // Query to get valid refresh tokens in the event that one didn't make it back to the client
  const [getRescueTokens] = useLazyQuery<ArrivalDisplayRescueQuery>(
    RESCUE_QUERY,
    {
      onCompleted: (data) => {
        // If we get a response back indicating that there are still valid refresh
        // tokens for the device, update our stored refresh token with that value.
        if (bearerToken && data.arrivalDisplaySelfRescue.length > 0) {
          setAuthTokens(bearerToken, data.arrivalDisplaySelfRescue[0]);
        } else {
          Sentry.captureMessage('No rescue tokens available', {
            level: Sentry.Severity.Debug,
            extra: {
              appVersion: RELEASE_VERSION,
              refreshToken,
              bearerToken,
            },
          });
        }
      },
      fetchPolicy: 'cache-and-network',
    }
  );

  // Query to determine if the arrival display is still considered paired
  const [getIsPaired] = useLazyQuery<IsArrivalDisplayPaired>(IS_PAIRED_QUERY, {
    onCompleted: (data) => {
      // If the device is still considered paired (hasn't been deleted by an admin),
      // initiate the self-rescue query to get a valid refresh token.
      if (data.isArrivalDisplayPaired) {
        getRescueTokens();
      } else {
        Sentry.captureMessage('Device deleted by admin', {
          level: Sentry.Severity.Debug,
          extra: {
            appVersion: RELEASE_VERSION,
          },
        });

        // Device is not paired and has likely been removed by an admin. Clear any tokens.
        clearAuthTokens();
      }
    },
    onError: async (error) => {
      // Device is 'stuck' and will not be able to make requests. Clear any tokens.
      if (isAuthError(error)) {
        Sentry.captureMessage('Device paired query auth error', {
          level: Sentry.Severity.Debug,
          extra: {
            appVersion: RELEASE_VERSION,
          },
        });

        clearAuthTokens();
      }
    },
    fetchPolicy: 'cache-and-network',
  });

  const authenticate = async () => {
    if (!refreshToken) {
      const error = new Error('Cannot authenticate without a refresh token');
      setValidationError(error);
      return;
    }

    let authResult:
      | RefreshArrivalDeviceToken_refreshDeviceAuthorization
      | undefined;

    try {
      // Hand in our refresh token for a new bearer/refresh token set
      // and store them in local storage (refresh token) or state (bearer)
      const variables = { refreshToken };
      const result = await authMutation({ variables });
      authResult = result?.data?.refreshDeviceAuthorization;

      if (authResult) {
        setHasAuthFailed(false);
        setLastAuthResponse(authResult);
        setAuthTokens(authResult.bearerToken, authResult.refreshToken);
        setTenantId(authResult.organizationId);
        willRetry = true;

        identifySentry(authResult);
        identifyAmplitude(
          authResult.organizationId,
          orgData?.getOrganizationById?.slug ?? 'unknown'
        );
      }

      getOrganization({
        variables: {
          organizationId: authResult?.organizationId ?? '',
        },
      });
    } catch (err) {
      // Clear tokens if the server rejected them. If we just got a 500 error or
      // something unrelated to auth, keep them around so the device doesn't
      // deregister itself. The refresh token might still be good.
      if (isAuthError(err)) {
        if (certKey) {
          await authenticateWithCert();
        } else {
          getIsPaired();
        }
      } else if (willRetry) {
        willRetry = false;

        setTimeout(async () => {
          await authenticate();
        }, AUTH_RETRY_DELAY_SECONDS);
      } else {
        setHasAuthFailed(true);
      }
    }
  };

  /**
   * Called when we get an unauthorized response from the standard refreshAuth
   * mutation. A new JWT is created and signed with our cert key, and used to
   * authenticate this call to get us new refresh and bearer tokens.
   */
  const authenticateWithCert = async () => {
    if (!certKey) return;

    const decodedJwt = decode(bearerToken || certKey);
    // Creates our new JWT signed with the signing certificate
    const jwt = generateSignedJwt(
      certKey,
      lastAuthResponse?.organizationId ?? tenantId ?? '',
      lastAuthResponse?.id ?? (decodedJwt?.sub as string)
    );
    // Set the context with the new JWT bearer token so it's on the Apollo context.
    setAuthTokens(jwt, lastAuthResponse?.refreshToken ?? refreshToken ?? ' ');
    // Attempt a recovery with the new JWT bearer token
    const response = await certRecoveryMutation({
      context: {
        headers: {
          authorization: `Bearer ${jwt}`,
        },
      },
    });
    const authResponse = response?.data?.arrivalDisplayCertRecovery;

    if (authResponse) {
      // If the device is not considered paired, it has been deleted by an admin
      if (authResponse.isPaired === false) {
        Sentry.captureMessage(
          'Cert authentication indicated device has been deleted',
          {
            level: Sentry.Severity.Debug,
            extra: {
              appVersion: RELEASE_VERSION,
            },
          }
        );

        clearAuthTokens();
      }

      if (authResponse.authorization) {
        setAuthTokens(
          authResponse.authorization.bearerToken,
          authResponse.authorization.refreshToken
        );
      }
    }
  };

  /**
   * Calls the temporary mutation to return authentication tokens and a cert key
   * for devices that are paired, but do not have a signing certificate yet.
   */
  const tempAuthenticate = async () => {
    const result = await tempRefreshMutation();
    const authData = result?.data?.tempArrivalDisplayAuthMigration;

    if (authData) {
      setAuthTokens(
        authData.authorization.bearerToken,
        authData.authorization.refreshToken
      );
      setCertKey(authData.certKey);
    }
  };

  // Called on mount. Calls the temporary mutation to authenticate and
  // return a cert key that can be used for future re-authentication.
  useEffect(() => {
    if (!certKey && bearerToken) tempAuthenticate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Keep refreshing the bearer token every couple minutes as soon as,
  // and as long as, a refresh token exists.
  useEffect(() => {
    let timerId: NodeJS.Timeout;

    if (refreshToken) {
      timerId = setTimeout(async () => {
        setIsAutoRefreshing(true);
        await authenticate();
        setIsAutoRefreshing(false);
      }, REFRESH_TOKEN_INTERVAL_MINS);
    }
    return () => clearTimeout(timerId);
  }, [refreshToken, hasAuthFailed]); // eslint-disable-line react-hooks/exhaustive-deps

  // Update identifiers once we have them
  useEffect(() => {
    if (orgData?.getOrganizationById && orgData.getOrganizationById.slug) {
      identifyAmplitude(
        orgData.getOrganizationById.id,
        orgData.getOrganizationById.slug
      );
    }
  }, [orgData?.getOrganizationById, identifyAmplitude]);

  // This `serverError` var captures failures that can be retried (think 500 error)
  // and drives a different error branch on the UI than an "invalid auth" state.
  // A `serverError` exists when:
  // 1. Fetching the organization fails after authenticating, or:
  // 2. The mutation to refresh the tokens failed for a reason other than the
  //    existing tokens being bad AND we've failed after retrying that mutation.
  let serverError;
  if (hasAuthFailed && !orgError && !isAuthError(authError)) {
    serverError = authError;
  }

  // Answers the question, "is initial authentication call in progress?"
  // Some considerations:
  // - We don't want to show the loading state if we shouldn't even try to authenticate
  //   the device because a refresh token doesn't exist (validationError check)
  // - We don't want to show the loading state when authentication is triggered
  //   by the automatic refresh every two minutes (isAutoRefreshing check)
  // - We don't want the loading state to thrash between the call to get new tokens
  //   and the call to fetch the organization.
  // - We don't want the loading state to thrash in the brief period of time between page
  //   load and when the authentication process is actually kicked off
  const initialAuthLoading = !authData && !authError;
  const initialOrgLoading = !!authData && !orgData && !orgError;
  const initialLoading =
    !validationError &&
    !isAutoRefreshing &&
    (initialAuthLoading || initialOrgLoading);

  // The dispay is considered authenticated once:
  // 1. Initial loading of all the things has completed
  // 2. After all the loading, a bearer and refresh token exist
  const organization = orgData?.getOrganizationById ?? undefined;
  const location = authData?.refreshDeviceAuthorization?.location;
  const isAuthenticated = !!(bearerToken && refreshToken);
  const deviceName = authData?.refreshDeviceAuthorization.deviceName;
  const deviceId = authData?.refreshDeviceAuthorization.id;

  return {
    loading: initialLoading,
    error: serverError,
    isAuthenticated,
    authenticate,
    organization,
    location,
    deviceName,
    deviceId,
  };
};
