import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled/macro';
import { Body05Styles, Button, Colors } from '@robinpowered/design-system';
import Globe from './icons/globe.svg';
import Checkmark from './icons/checkmark.svg';
import { useEffect, useState } from 'react';
import { i18n } from 'i18n';
import { useLocalStorage } from 'react-use';

type Language = {
  displayName: string;
  slug: string;
};

const languages: Language[] = [
  {
    displayName: 'English - US',
    slug: 'en',
  },
  {
    displayName: 'Español',
    slug: 'es',
  },
  {
    displayName: 'Français - France',
    slug: 'fr',
  },
  {
    displayName: 'Français - CA',
    slug: 'fr-ca',
  },
  {
    displayName: 'Deutsch',
    slug: 'de',
  },
  {
    displayName: '日本語',
    slug: 'ja',
  },
];

const LOCAL_STORAGE_LANGUAGE_KEY = '_rbn-lang-flag';

export const LanguageSelector: React.FC = () => {
  const { t } = useTranslation('welcome');
  const [shouldSelectorShow, setShouldSelectorShow] = useState<boolean>(false);
  const [activeLanguageSlug, setSelectedLanguageSlug] = useLocalStorage<string>(
    LOCAL_STORAGE_LANGUAGE_KEY
  );
  const handleLanguageSelection = (slug: string) => {
    setSelectedLanguageSlug(slug);
    i18n.changeLanguage(slug);
    setShouldSelectorShow(false);
  };

  useEffect(() => {
    i18n.changeLanguage(activeLanguageSlug ?? 'en');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <LanguageSelectorContainer>
      <Button
        variant="secondary"
        size="default"
        name={t('language_selector')}
        onClick={() => setShouldSelectorShow(!shouldSelectorShow)}
        style={{
          borderRadius: 16,
          padding: 16,
          marginBottom: 8,
        }}
      >
        <GlobeIcon src={Globe} />
      </Button>

      {shouldSelectorShow && (
        <LanguageWrapper>
          <LanguageHeader>{t('select_language')}</LanguageHeader>
          {languages.map((language) => (
            <LanguageEntry
              active={language.slug === activeLanguageSlug}
              key={language.displayName}
              onClick={() => handleLanguageSelection(language.slug)}
            >
              {language.displayName}
            </LanguageEntry>
          ))}
        </LanguageWrapper>
      )}
    </LanguageSelectorContainer>
  );
};

export const LanguageSelectorContainer = styled.div`
  position: relative;
`;

const GlobeIcon = styled.img`
  width: 22px;
`;

const LanguageWrapper = styled.ul`
  background-color: ${Colors.White0};
  border: 1px solid ${Colors.Tan70};
  box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.15), 0px 2px 12px rgba(0, 0, 0, 0.06);
  border-radius: 8px;
  padding: 8px 0;
  margin: 0;
  position: absolute;
  right: 0;
  width: 224px;
`;

const LanguageHeader = styled.li`
  ${Body05Styles};
  color: ${Colors.Gray100};
  list-style: none;
  padding: 4px 16px;
`;

const LanguageEntry = styled.li`
  ${Body05Styles};
  color: ${Colors.Gray80};
  cursor: pointer;
  list-style: none;
  padding: 4px 16px 4px 40px;
  position: relative;

  ${({ active }: { active?: boolean }) =>
    active &&
    `
    background-color: ${Colors.Magenta100};
    color: ${Colors.White0};

    &::before {
      content: "";
      background: url(${Checkmark});
      background-repeat: no-repeat;
      height: 10px;
      width: 12px;
      position: absolute;
      margin-left: -20px;
      top: 50%;
      transform: translateY(-50%);
    }
  `};

  &:hover {
    background-color: ${Colors.Magenta100};
    color: ${Colors.White0};
  }
`;
