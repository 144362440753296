import { Body01, Colors, Heading01 } from '@robinpowered/design-system';
import { ContentWrapper, Header } from 'components';
import { useTranslation } from 'react-i18next';
import { useTimedRedirect } from 'hooks';

const CHECKING_REDIRECT_SECONDS = 1000 * 5; // 5 seconds

export const HealthCheckpointChecking = (): JSX.Element => {
  const { t } = useTranslation('healthCheckpoint');

  useTimedRedirect('completed', CHECKING_REDIRECT_SECONDS);

  return (
    <>
      <Header
        backHidden
        isLoading={true}
        onPrevClick={() => {}}
        nextButtonText={t('done')}
        nextDisabled={true}
        onNextClick={() => {}}
      />

      <ContentWrapper>
        <Heading01
          role="alert"
          style={{ fontSize: '26px', lineHeight: '36px', textAlign: 'center' }}
          mb={'8px'}
        >
          {t('survey_checking.heading')}
        </Heading01>
        <Body01
          color={Colors.Gray40}
          style={{
            lineHeight: '32px',
            fontSize: '22px',
            padding: '0 36px',
            textAlign: 'center',
          }}
        >
          {t('survey_checking.body')}
        </Body01>
      </ContentWrapper>
    </>
  );
};
