import { useQuery, QueryResult } from '@apollo/client';
import { gql } from 'graphql-tag';
import { IsGuestSelfRegistrationEnabled } from './__generated__/IsGuestSelfRegistrationEnabled';

export const GET_SELF_REGISTRATION_ENABLED = gql`
  query IsGuestSelfRegistrationEnabled {
    isGuestSelfRegistrationEnabled
  }
`;

export const useSelfRegistrationEnabled =
  (): QueryResult<IsGuestSelfRegistrationEnabled> => {
    return useQuery<IsGuestSelfRegistrationEnabled>(
      GET_SELF_REGISTRATION_ENABLED,
      {
        fetchPolicy: 'cache-and-network',
        notifyOnNetworkStatusChange: true,
      }
    );
  };
