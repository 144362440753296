import { useEffect } from 'react';
import styled from '@emotion/styled/macro';
import { BoxWrapper, HandleOutsideClicks } from 'components';
import { VisitLookup } from './VisitLookup';
import { CheckoutLoading } from './CheckoutLoading';
import { CheckoutComplete } from './CheckOutComplete';
import { CheckoutFailed } from './CheckoutFailed';
import { useCheckOutContext } from './CheckOutContext';

const REDIRECT_SECONDS = 1000 * 60 * 5; // 5 minutes
const ANIMATION_DURATION_MILLIS = 200; // Milliseconds

enum ScreenState {
  VISIT_LOOKUP,
  CHECKOUT_LOADING,
  CHECKOUT_ERROR,
  CHECKOUT_COMPLETE,
}

type CheckOutModalArgs = {
  closeModal: () => void;
};

export const CheckOutModal: React.FC<CheckOutModalArgs> = ({ closeModal }) => {
  const {
    mutationResult: {
      loading: checkoutLoading,
      error: checkoutError,
      reset,
      abortDelayedMutation,
      called,
    },
  } = useCheckOutContext();

  let screenState: ScreenState;
  if (checkoutLoading) {
    screenState = ScreenState.CHECKOUT_LOADING;
  } else if (checkoutError) {
    screenState = ScreenState.CHECKOUT_ERROR;
  } else if (called) {
    screenState = ScreenState.CHECKOUT_COMPLETE;
  } else {
    screenState = ScreenState.VISIT_LOOKUP;
  }

  useEffect(() => {
    const timerId = setTimeout(closeModal, REDIRECT_SECONDS);
    return () => clearTimeout(timerId);
  }, [closeModal]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleOutsideModalClick = () => {
    if (checkoutLoading) return;
    closeModal();
  };

  return (
    <>
      <BlurScreenBackground>
        <HandleOutsideClicks
          style={{
            position: 'relative',
            top: '15vh',
          }}
          callback={handleOutsideModalClick}
        >
          <Modal>
            {screenState === ScreenState.VISIT_LOOKUP && (
              <VisitLookup onPressCancel={closeModal} />
            )}
            {screenState === ScreenState.CHECKOUT_LOADING && (
              <CheckoutLoading onPressCancel={abortDelayedMutation} />
            )}
            {screenState === ScreenState.CHECKOUT_ERROR && (
              <CheckoutFailed onPressCancel={closeModal} onPressRetry={reset} />
            )}
            {screenState === ScreenState.CHECKOUT_COMPLETE && (
              <CheckoutComplete onPressClose={closeModal} />
            )}
          </Modal>
        </HandleOutsideClicks>
      </BlurScreenBackground>
    </>
  );
};

const Modal = styled(BoxWrapper)`
  width: 640px;
`;

const BlurScreenBackground = styled.div`
  transition: backdrop-filter ${ANIMATION_DURATION_MILLIS / 1000}s;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 4;
  backdrop-filter: blur(10px);
  background: rgba(0, 0, 0, 0.3);
  display: flex;
  flex-direction: column;
  align-items: center;
`;
