import { FormEvent, SyntheticEvent, useEffect, useState } from 'react';
import styled from '@emotion/styled/macro';
import {
  Heading03,
  Body05,
  Body07,
  Box,
  Row,
  Button,
  Colors,
  ErrorBadge,
  InputField,
} from '@robinpowered/design-system';
import { ApolloError, useLazyQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import graphic from 'media/add-device-graphic.png';
import {
  CenteredContent,
  InputWrapper,
  UnauthenticatedHeader,
  Heading00,
} from 'components';
import { gql } from 'graphql-tag';
import { useHistory, useLocation } from 'react-router';
import { usePageLoadedEvent } from 'contexts';
import {
  GetUnauthorizedDisplayDetails,
  GetUnauthorizedDisplayDetailsVariables,
} from './__generated__/GetUnauthorizedDisplayDetails';
import { RegionPicker } from './components/RegionPicker';

export const GET_UNAUTHORIZED_DISPLAY_DETAILS = gql`
  query GetUnauthorizedDisplayDetails(
    $orgId: ID!
    $registrationCode: ArrivalDisplayRegistrationKey!
  ) {
    getUnauthorizedArrivalDisplayDetails(
      orgId: $orgId
      registrationCode: $registrationCode
    ) {
      id
    }
  }
`;

const isBadUserInput = (error: ApolloError): boolean => {
  return error.graphQLErrors?.[0]?.extensions?.code === 'BAD_USER_INPUT';
};

const isRegistrationCodeExpiredError = (error: ApolloError): boolean => {
  return (
    error.graphQLErrors?.[0]?.extensions?.code === 'REGISTRATION_CODE_EXPIRED'
  );
};

export const RegisterInstructions = (): JSX.Element => {
  const { t } = useTranslation('registerInstructions');
  const [orgCode, setOrgCode] = useState('');
  const [orgId, registrationCode] = orgCode.split('/');
  const history = useHistory();
  const { state } = useLocation<{ error?: string }>();
  const [validationError, setValidationError] = useState('');
  const [getDisplayDetails, { loading, error, data }] = useLazyQuery<
    GetUnauthorizedDisplayDetails,
    GetUnauthorizedDisplayDetailsVariables
  >(GET_UNAUTHORIZED_DISPLAY_DETAILS);
  const displayDetails = data?.getUnauthorizedArrivalDisplayDetails;

  usePageLoadedEvent('register-instructions', false);

  useEffect(() => {
    if (displayDetails) {
      history.push(`/${orgId}/${registrationCode}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [displayDetails, orgId, registrationCode]);

  const fetchDetails = () => {
    if (!orgId || !registrationCode) {
      // A code may have been entered but we couldn't parse the org/key out of it
      setValidationError(t('error.bad_input'));
      return;
    }
    setValidationError('');
    getDisplayDetails({
      variables: {
        orgId,
        registrationCode,
      },
    });
  };

  let errorMessage = '';
  if (displayDetails === null) {
    errorMessage = t('error.already_registered');
  } else if (validationError || (error && isBadUserInput(error))) {
    errorMessage = t('error.bad_input');
  } else if (error && isRegistrationCodeExpiredError(error)) {
    errorMessage = t('error.registration_code_expired');
  } else if (state?.error) {
    errorMessage = state.error;
  }

  return (
    <CenteredContentNarrow>
      <MainContent>
        <UnauthenticatedHeader
          style={{
            // Spacing for the top of the screen
            // Spaces about 1/3 the free space, so content is off-center but near the top.
            flex: 0.25,
            minHeight: '60px',
          }}
        />

        <RegionPicker />

        {/* Use an error badge if we didn't capture a message to display inline */}
        {error && !errorMessage && (
          <ErrorBadge
            message={t('error.server_error')}
            style={{
              marginTop: '-56px',
              marginBottom: '20px',
              paddingBottom: '6px',
              paddingTop: '6px',
            }}
          />
        )}

        <Box
          borderRadius={24}
          boxShadow="0px 1px 3px rgba(0, 0, 0, 0.08)"
          p={48}
          mb={4}
          backgroundColor={Colors.White0}
        >
          <Heading00 mb={3}>{t('input_heading')}</Heading00>
          <FormRow
            onSubmit={(event: FormEvent<HTMLFormElement>) => {
              event.preventDefault();
              fetchDetails();
            }}
          >
            <InputWrapper>
              <InputField
                placeholder={t('input_placeholder')}
                type="text"
                required
                onChange={(event: SyntheticEvent<HTMLInputElement>) => {
                  setOrgCode(event.currentTarget.value);
                }}
                error={errorMessage.length > 0}
                errorText={errorMessage}
                value={orgCode}
                style={{
                  borderRadius: '16px',
                  fontSize: '22px',
                  lineHeight: '26px',
                  padding: '16px 18px',
                  margin: 0,
                }}
              />
            </InputWrapper>
            <InputButton
              disabled={orgCode.length < 1}
              isLoading={loading}
              variant="primary"
              name={t('input_button')}
              onClick={fetchDetails}
            >
              {t('input_button')}
            </InputButton>
          </FormRow>
        </Box>

        <Box borderRadius={24} px={48} pt={48} backgroundColor={Colors.Tan30}>
          <Heading03 mb={2}>{t('instructions_heading')}</Heading03>
          <Step number={1} />
          <Step number={2} />
          <Step number={3} />
          <Graphic alt={t('image_label')} src={graphic} />
        </Box>
      </MainContent>
    </CenteredContentNarrow>
  );
};

const Step = ({ number }: { number: 1 | 2 | 3 }) => {
  const t: (arg0: string) => string = useTranslation('registerInstructions').t;
  return (
    <Row mb={2}>
      <StepNumberCircle>{number}</StepNumberCircle>
      <Body05>{t(`instructions_step.${number}`)}</Body05>
    </Row>
  );
};

const CenteredContentNarrow = styled(CenteredContent)`
  padding: 42px 125px 42px;
`;

const MainContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const Graphic = styled.img`
  margin-top: 32px;
  margin-bottom: -6px; // Hide the divider effect
  max-width: 100%;
  background-color: ${Colors.Tan5};
`;

const FormRow = styled.form`
  display: flex;
`;

const StepNumberCircle = styled(Body07)`
  width: 19px;
  height: 19px;
  border-radius: 50%;
  margin-right: 8px;
  background-color: ${Colors.Tan70};
  padding: 3px 0 0 0; // Larsseit text is off-center
  display: flex;
  align-items: center;
  justify-content: center;
`;

const InputButton = styled(Button)`
  align-self: flex-start;
  border-radius: 8px;
  border-width: 1px;
  height: auto;
  font-size: 22px;
  line-height: 28px;
  margin: 0 0 0 8px;
  padding: 16px 30px;

  &:disabled {
    border-color: transparent;
    background-color: ${Colors.Tan5};
  }
`;
