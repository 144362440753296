import styled from '@emotion/styled/macro';
import { useTranslation } from 'react-i18next';
import { Row, Heading04, Body05, Button } from '@robinpowered/design-system';

type ErrorRowArgs = {
  onPressRetry: () => void;
};

export const ErrorRow: React.FC<ErrorRowArgs> = ({ onPressRetry }) => {
  const { t } = useTranslation('checkOut');

  return (
    <RowContainer>
      <Heading04 mb="8px">{t('lookup_error.title')}</Heading04>
      <Body05 mb="12px">{t('lookup_error.subtitle')}</Body05>
      <Button variant="secondary" onClick={onPressRetry}>
        {t('retry')}
      </Button>
    </RowContainer>
  );
};
const RowContainer = styled(Row)`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  margin: 36px 24px 32px 24px;
`;
