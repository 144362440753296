import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { RefreshArrivalDeviceToken_refreshDeviceAuthorization } from 'hooks/__generated__/RefreshArrivalDeviceToken';
import { history } from './history';
import LogRocket from 'logrocket';

const SENTRY_ENV = process.env.REACT_APP_SENTRY_ENV || 'development';

Sentry.init({
  enabled: process.env.NODE_ENV !== 'development',
  environment: SENTRY_ENV,
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [
    new Integrations.BrowserTracing({
      routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
    }),
  ],
  tracesSampleRate: 1.0,
  normalizeDepth: 10,
});

export const identify = (
  device: RefreshArrivalDeviceToken_refreshDeviceAuthorization
): void => {
  LogRocket.getSessionURL((sessionURL) => {
    Sentry.configureScope((scope) => {
      scope.setTag('organizationId', device.organizationId);
      scope.setContext('Organization', {
        id: device.organizationId,
      });
      scope.setContext('Display', {
        id: device.id,
        name: device.deviceName,
        locationId: device.location.id,
        locationName: device.location.name,
      });
      scope.setExtra('LogRocket sessionURL', sessionURL);

      if (SENTRY_ENV === 'production') {
        scope.setExtra('App Version', process.env.REACT_APP_RELEASE_VERSION);
      }
    });
  });
};

export { Sentry };
