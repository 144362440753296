import { ApolloError, ApolloQueryResult, useQuery } from '@apollo/client';
import { gql } from 'graphql-tag';
import {
  GetDisplayDetailsQuery,
  GetDisplayDetailsQueryVariables,
  GetDisplayDetailsQuery_getUnauthorizedArrivalDisplayDetails,
} from './__generated__/GetDisplayDetailsQuery';

const DISPLAY_DETAILS_QUERY = gql`
  query GetDisplayDetailsQuery(
    $organizationId: ID!
    $registrationKey: ArrivalDisplayRegistrationKey!
  ) {
    getUnauthorizedArrivalDisplayDetails(
      orgId: $organizationId
      registrationCode: $registrationKey
    ) {
      id
      name
      location {
        id
        name
      }
      organization {
        id
        name
        logo
      }
    }
  }
`;

type UseDisplayDetails = {
  details:
    | GetDisplayDetailsQuery_getUnauthorizedArrivalDisplayDetails
    | null
    | undefined;
  loading: boolean;
  error: ApolloError | undefined;
  refetch: (
    variables?: Partial<GetDisplayDetailsQueryVariables> | undefined
  ) => Promise<ApolloQueryResult<GetDisplayDetailsQuery>>;
};

export const useDisplayDetails = (
  organizationId: string,
  registrationKey: string
): UseDisplayDetails => {
  const { data, error, loading, called, refetch } = useQuery<
    GetDisplayDetailsQuery,
    GetDisplayDetailsQueryVariables
  >(DISPLAY_DETAILS_QUERY, {
    variables: {
      organizationId,
      registrationKey,
    },
  });

  return {
    details: data?.getUnauthorizedArrivalDisplayDetails,
    loading: !called || loading,
    error,
    refetch,
  };
};
