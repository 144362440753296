import moment from 'moment-timezone';
import { i18n } from 'i18n';

export const formatStartTimeMessage = (
  startTime: string,
  timezone: string | null,
  showDateString?: boolean
): string => {
  if (!timezone) {
    timezone = moment.tz.guess();
  }

  const date = moment.tz(startTime, timezone);
  let datePart = '';

  if (date.isSame(moment.tz(timezone), 'day') && !showDateString) {
    datePart = i18n.t('common:today');
  } else {
    datePart = date.format('ddd, MMM DD');
  }

  return `${datePart} @ ${date.format('h:mm a')}`;
};

export const formatStartTime = (
  startTime: string,
  timezone: string | null
): string => {
  if (!timezone) {
    timezone = moment.tz.guess();
  }

  return moment.tz(startTime, timezone).format('h:mm a');
};

export const formatBadgeStartTime = (
  startTime: string,
  timezone: string | null
): string => {
  if (!timezone) {
    timezone = moment.tz.guess();
  }

  const datePart = moment.tz(startTime, timezone).format('MMM DD, YYYY');
  const timePart = moment.tz(startTime, timezone).format('h:mm a');

  return `${datePart} at ${timePart}`;
};
