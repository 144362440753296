import { gql } from 'graphql-tag';
import {
  useMutation,
  MutationTuple,
  MutationHookOptions,
} from '@apollo/client';
import {
  DecideOutcome,
  DecideOutcomeVariables,
} from './__generated__/DecideOutcome';

const DOCUMENT_AGREEMENT_MUTATION = gql`
  mutation DecideOutcome(
    $visitId: ID!
    $visitInviteId: ID!
    $guestId: ID!
    $workflowId: ID!
    $outcomeId: ID!
    $sendCopyToGuest: Boolean
  ) {
    decideOutcomeForVisitDocumentFromArrivalDisplay(
      visitId: $visitId
      visitInviteId: $visitInviteId
      guestId: $guestId
      workflowId: $workflowId
      outcomeId: $outcomeId
      sendCopyToGuest: $sendCopyToGuest
    )
  }
`;

/**
 * Registers a guest for a visit that does not require a health checkpoint.
 * This is simpler than self-registered visits requiring a health checkpoint
 * because we do not need to generate as many ids to create the HCP beforehand.
 */
export const useDocumentAgreement = (
  options?: MutationHookOptions<DecideOutcome, DecideOutcomeVariables>
): MutationTuple<DecideOutcome, DecideOutcomeVariables> => {
  return useMutation<DecideOutcome, DecideOutcomeVariables>(
    DOCUMENT_AGREEMENT_MUTATION,
    options
  );
};
