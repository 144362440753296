import { useTranslation } from 'react-i18next';
import { Row, Colors } from '@robinpowered/design-system';
import MinorWarningSolid from '@robinpowered/icons/v2/MinorWarningSolid';
import styled from '@emotion/styled/macro';
import { ArrivalDisplayButton, Heading00 } from 'components';

type CheckoutFailedArgs = {
  onPressCancel: () => void;
  onPressRetry: () => void;
};

export const CheckoutFailed: React.FC<CheckoutFailedArgs> = ({
  onPressCancel,
  onPressRetry,
}) => {
  const { t } = useTranslation('checkOut');

  return (
    <>
      <CenteredRow mb="24px">
        <MinorWarningSolid size={30} color={Colors.RedProduct} />
        <Heading00 style={{ marginLeft: '10px' }}>
          {t('checkout_failure_message')}
        </Heading00>
      </CenteredRow>
      <Row>
        <ArrivalDisplayButton
          style={{
            margin: '0 16px 0 auto',
          }}
          variant="secondary"
          onClick={onPressCancel}
        >
          {t('cancel')}
        </ArrivalDisplayButton>
        <ArrivalDisplayButton variant="primary" onClick={onPressRetry}>
          {t('retry')}
        </ArrivalDisplayButton>
      </Row>
    </>
  );
};

const CenteredRow = styled(Row)`
  align-items: center;
`;
