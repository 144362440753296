import { useEffect } from 'react';
import moment from 'moment-timezone';
import { useLazyQuery } from '@apollo/client';
import { gql } from 'graphql-tag';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import {
  useDeviceContext,
  usePageLoadedEvent,
  useSelfRegisteredGuestContext,
  useVisitContext,
} from 'contexts';
import { Heading01 } from '@robinpowered/design-system';
import { Header, ContentWrapper } from 'components';
import { RegistrationServerError } from 'pages/GuestRegistrationHcpLoading/components';
import { VisitAsyncRequestStatus } from '__generated__/globalTypes';
import {
  GetVisitAsyncRequestStatus,
  GetVisitAsyncRequestStatusVariables,
} from './__generated__/GetVisitAsyncRequestStatus';
import { useRegisterWithHcpMutation } from './hooks/useRegisterWithHcpMutation';

const REGISTRATION_STATUS_QUERY = gql`
  query GetVisitAsyncRequestStatus($visitId: ID!) {
    getVisitAsyncRequestStatus(visitId: $visitId) {
      status
      errorText
    }
  }
`;

export const GuestRegistrationHcpLoading = (): JSX.Element => {
  usePageLoadedEvent('self-registration-health-checkpoint-loading', false);
  const { t } = useTranslation('guestRegistrationHcpLoading');
  const history = useHistory();
  const { setVisitId } = useVisitContext();
  const { location } = useDeviceContext();
  const {
    guestName,
    guestEmail,
    guestHost,
    guestVisitType,
    visitId,
    guestInviteId,
    healthCheckpointId,
  } = useSelfRegisteredGuestContext();
  const [
    registerWithHcpMutation,
    {
      error: registrationError,
      called: registrationCalled,
      loading: registrationLoading,
    },
  ] = useRegisterWithHcpMutation();
  const [
    pollForStatus,
    {
      data: registrationStatusData,
      error: registrationStatusError,
      stopPolling,
    },
  ] = useLazyQuery<
    GetVisitAsyncRequestStatus,
    GetVisitAsyncRequestStatusVariables
  >(REGISTRATION_STATUS_QUERY, {
    variables: { visitId },
    fetchPolicy: 'no-cache',
    nextFetchPolicy: 'no-cache',
    pollInterval: 1000,
  });

  const registerWithHcp = () => {
    if (guestHost && guestVisitType && location?.id) {
      registerWithHcpMutation({
        variables: {
          visitId,
          guestInviteId,
          healthCheckpointId,
          guestName,
          guestEmail,
          startTime: moment.utc().toISOString(),
          arrivalLocationId: location?.id,
          hostUserId: guestHost.id,
          visitType: guestVisitType,
        },
      });
    }
  };

  // on page mount, register the guest
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => registerWithHcp(), []);

  // start polling for registration completion after registration initiation is successful
  useEffect(() => {
    if (registrationCalled && !registrationLoading && !registrationError) {
      pollForStatus();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [registrationCalled, registrationLoading, registrationError]);

  // We can stop polling if the registration immediately fails
  // or if the polling query eventually returns
  useEffect(() => {
    if (
      registrationError ||
      registrationStatusError ||
      registrationStatusData?.getVisitAsyncRequestStatus?.status ===
        VisitAsyncRequestStatus.COMPLETE ||
      registrationStatusData?.getVisitAsyncRequestStatus?.status ===
        VisitAsyncRequestStatus.ERROR
    ) {
      stopPolling && stopPolling();
    }
    return () => {
      stopPolling && stopPolling();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [registrationError, registrationStatusData, registrationStatusError]);

  // if polling returns a successful status, transition to the HCP
  useEffect(() => {
    if (
      registrationStatusData?.getVisitAsyncRequestStatus?.status ===
      VisitAsyncRequestStatus.COMPLETE
    ) {
      setVisitId?.(guestInviteId);
      history.push('/guest-registration/health-checkpoint');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [registrationStatusData]);

  if (
    registrationError ||
    registrationStatusError ||
    registrationStatusData?.getVisitAsyncRequestStatus?.status ===
      VisitAsyncRequestStatus.ERROR
  )
    return <RegistrationServerError onPressRetry={() => registerWithHcp()} />;

  return (
    <>
      <Header
        isLoading
        onPrevClick={() => {}}
        onNextClick={() => {}}
        backDisabled
      />
      <ContentWrapper>
        <Heading01 id="heading" display="flex" justifyContent="center">
          {t('registering_guest_body')}
        </Heading01>
      </ContentWrapper>
    </>
  );
};
