import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled/macro';
import { ContentWrapper, Header, InputWrapper } from 'components';
import { useHistory, useLocation } from 'react-router';
import { useTimedRedirect } from 'hooks';
import { usePageLoadedEvent, useSelfRegisteredGuestContext } from 'contexts';
import { Heading01, InputField } from '@robinpowered/design-system';
import { SyntheticEvent, useEffect, useState } from 'react';
import {
  getUsersByKeyword_getUsersByKeyword,
  HostSelectConnector,
  SelectedHost,
} from './components';
import { isValidEmail } from 'utils';

const NAME_REQUIRED_LENGTH = 2;

export type PreFillGuestData = {
  email?: string;
};

export const GuestRegistrationDetails = (): JSX.Element => {
  const { t } = useTranslation('guestRegistration');
  const history = useHistory();
  const { state: locationState } = useLocation<PreFillGuestData | undefined>();
  const [shouldValidate, setShouldValidate] = useState<boolean>(false);
  const {
    guestName,
    guestEmail,
    guestHost,
    setGuestName,
    setGuestEmail,
    setGuestHost,
  } = useSelfRegisteredGuestContext();
  const isValidName = guestName.length >= NAME_REQUIRED_LENGTH;
  const isValidForm = isValidName && isValidEmail(guestEmail) && guestHost;
  useEffect(() => {
    const preFilledEmail = locationState?.email;
    if (preFilledEmail) {
      setGuestEmail(preFilledEmail);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  usePageLoadedEvent('self-registration-details', false);
  useTimedRedirect();

  const handleSubmit = () => {
    setShouldValidate(true);

    if (isValidForm) {
      history.push('/guest-registration/visit-type');
    }
  };

  return (
    <>
      <Header
        isLoading={false}
        onPrevClick={() => {
          history.push('/');
        }}
        onNextClick={handleSubmit}
      />

      <ContentWrapper>
        <Heading01 style={{ fontSize: 30, lineHeight: '38px' }} mb={'24px'}>
          {t('heading')}
        </Heading01>
        <form
          onSubmit={(event) => {
            handleSubmit();
            event.preventDefault(); // Don't redirect page
          }}
        >
          <InputWrapper>
            <InputField
              id="name"
              autoComplete="off"
              label={t('name_label')}
              name={t('name_label')}
              errorText={t('errors.name')}
              error={shouldValidate && !isValidName}
              showLabel
              mb={'24px'}
              onChange={(event: SyntheticEvent<HTMLInputElement>) => {
                setGuestName(event.currentTarget.value);
              }}
              style={{
                fontSize: '26px',
                padding: '20px 18px', // Will get 6px horizontal padding
              }}
              value={guestName}
            />
          </InputWrapper>
          <InputWrapper>
            <label>{t('host_label')}</label>
            {guestHost ? (
              <SelectedHost
                host={guestHost}
                onClear={() => setGuestHost(null)}
              />
            ) : (
              <HostSelectConnector
                id="hostId"
                onSelectUser={(
                  hostUser: getUsersByKeyword_getUsersByKeyword
                ) => {
                  setGuestHost(hostUser);
                }}
                onBlur={() => {}}
                formError={shouldValidate && !guestHost}
                errorText={t('errors.host')}
              />
            )}
          </InputWrapper>

          {/* Submit button hidden to allow for easy keyboard submission and screen reader awareness */}
          <HiddenSubmit type="submit" />
        </form>
      </ContentWrapper>
    </>
  );
};

const HiddenSubmit = styled.input`
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
`;
