import { useTranslation } from 'react-i18next';
import Ex from '@robinpowered/icons/v2/X';
import { KeyboardEvent } from 'react';
import styled from '@emotion/styled/macro';

type PropTypes = {
  onClick: () => void;
};

export const ClearButton = ({ onClick }: PropTypes): JSX.Element => {
  const { t } = useTranslation('hostSelect');
  const onKeyDown = (event: KeyboardEvent<HTMLAnchorElement>) => {
    if (event.key === 'Enter') {
      onClick();
    }
  };

  return (
    <Container
      aria-label={t('clear')}
      tabIndex={0}
      onClick={() => onClick()}
      onKeyDown={onKeyDown}
    >
      <Ex size={14} />
    </Container>
  );
};

const Container = styled.a`
  cursor: pointer;
  padding: 11px;
  margin-left: 4px;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    margin-top: 0;
  }

  path {
    stroke-width: 4px;
  }
`;
