import React, { FC } from 'react';
import styled from '@emotion/styled/macro';
import { CompanyLogo } from 'components';

const robinLogoUrl =
  'https://static.robinpowered.com/brand/public/logos/full-color.png';

export const UnauthenticatedHeader: FC<{ style?: React.CSSProperties }> = (
  props
) => (
  <CenteredHeading {...props}>
    <CompanyLogo companyName="Robin" imageSource={robinLogoUrl} />
  </CenteredHeading>
);

const CenteredHeading = styled.div`
  text-align: center;

  img {
    max-width: 80px;
  }
`;
