import styled from '@emotion/styled/macro';
import { Colors } from '@robinpowered/design-system';

export const Field = styled.div<{ last?: boolean }>`
  font-size: 20px;
  line-height: 32px;
  margin: 0 16px;
  padding: 12px 0;
  word-break: break-word;
  // respect line breaks and new lines in field text
  white-space: pre-wrap;

  ${(props) =>
    !props.last &&
    `
    border-bottom: 1px solid ${Colors.Tan10};
  `}
`;

export const FieldLabel = styled.div`
  color: ${Colors.Gray90};
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  margin-bottom: 4px;
`;
