import styled from '@emotion/styled/macro';
import {
  Button,
  Heading01,
  Body01,
  Colors,
  Row,
} from '@robinpowered/design-system';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { ContentWrapper, Header } from 'components';
import graphic from 'media/error-device-graphic.png';
import { useMinimumLoading } from 'hooks';

/**
 * A fullscreen error page for generic errors and edge cases.
 *
 * Includes options to retry and escape back to the main welcome screen.
 */
export const FullPageError = ({
  onPressRetry,
  retryLoading = false,
}: {
  onPressRetry?: () => unknown;
  retryLoading?: boolean;
}): JSX.Element => {
  const { t } = useTranslation('common');
  const history = useHistory();
  const isRetryLoading = useMinimumLoading(retryLoading);
  const showRetryButton = !!onPressRetry;

  return (
    <>
      <Header
        backHidden
        nextHidden
        onNextClick={() => {}}
        onPrevClick={() => {}}
      />
      <ContentWrapper
        mainContentStyle={{ paddingTop: 0, paddingBottom: 0 }}
        boxStyle={{
          backgroundColor: Colors.Tan5,
          boxShadow: 'none',
          paddingTop: 0,
          paddingBottom: 0,
          display: 'flex',
          flex: 1,
          flexDirection: 'column',
        }}
      >
        {/* For taller displays, show content centered, but slightly higher */}
        <div style={{ display: 'flex', flex: 0.3 }} />
        <Graphic src={graphic} />
        <Heading01
          mb={3}
          style={{
            fontSize: '26px',
            lineHeight: '36px',
            textAlign: 'center',
          }}
        >
          {t('generic_error_page.heading')}
        </Heading01>
        <Body01 mb={4} style={{ lineHeight: '41px', textAlign: 'center' }}>
          {t('generic_error_page.body')}
        </Body01>
        <Row display="flex" justifyContent="center">
          {showRetryButton && (
            <StyledButton
              mr="4"
              isLoading={isRetryLoading}
              aria-label={t('generic_error_page.try_again')}
              variant="primary"
              onClick={() => onPressRetry()}
            >
              {t('generic_error_page.try_again')}
            </StyledButton>
          )}
          <StyledButton
            variant={'tertiary'}
            aria-label={t('generic_error_page.go_home')}
            onClick={() => history.push('/')}
          >
            {t('generic_error_page.go_home')}
          </StyledButton>
        </Row>
      </ContentWrapper>
    </>
  );
};

const Graphic = styled.img`
  margin-bottom: 95px;
  max-width: 100%;
`;

const StyledButton = styled(Button)`
  box-shadow: none;
  border-radius: 16px;
  padding: 16px 40px;
  font-size: 22px;
  line-height: 28px;
  height: auto;
`;
