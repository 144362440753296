import { gql } from 'graphql-tag';
import { useMutation, MutationTuple } from '@apollo/client';
import {
  RegisterGuestWithHCPFromArrivalDisplayWithCustomVisitType,
  RegisterGuestWithHCPFromArrivalDisplayWithCustomVisitTypeVariables,
} from './__generated__/RegisterGuestWithHCPFromArrivalDisplayWithCustomVisitType';

/**
 * NOTE: This query works asynchronously, a successful response means an async
 * process was kicked off to create both HCP and visit. To get the resulting visit,
 * poll `getVisitAsyncRequestStatus` until it returns a success.
 */
const REGISTER_WITH_HCP_MUTATION = gql`
  mutation RegisterGuestWithHCPFromArrivalDisplayWithCustomVisitType(
    $visitId: ID!
    $guestInviteId: ID!
    $healthCheckpointId: ID!
    $guestName: String!
    $guestEmail: String!
    $startTime: ISODateTime!
    $arrivalLocationId: ID!
    $hostUserId: ID!
    $visitType: String!
  ) {
    registerGuestWithHCPFromArrivalDisplayWithCustomVisitType(
      visitId: $visitId
      guestInviteId: $guestInviteId
      healthCheckpointId: $healthCheckpointId
      guestName: $guestName
      guestEmail: $guestEmail
      startTime: $startTime
      arrivalLocationId: $arrivalLocationId
      hostUserId: $hostUserId
      visitType: $visitType
    )
  }
`;

/**
 * Registers a guest (creates a visit) and creates a health checkpoint (HCP).
 *
 * This should be used only in buildings where HCPs are enabled.
 * If HCPs are not enabled for the building, use useRegisterWithoutHcpMutation instead.
 *
 * NOTE: This query works asynchronously, a successful response means an async
 * process was kicked off to create both HCP and visit. To get the resulting visit,
 * poll `getVisitAsyncRequestStatus` until it returns a success.
 */
export const useRegisterWithHcpMutation = (): MutationTuple<
  RegisterGuestWithHCPFromArrivalDisplayWithCustomVisitType,
  RegisterGuestWithHCPFromArrivalDisplayWithCustomVisitTypeVariables
> => {
  return useMutation<
    RegisterGuestWithHCPFromArrivalDisplayWithCustomVisitType,
    RegisterGuestWithHCPFromArrivalDisplayWithCustomVisitTypeVariables
  >(REGISTER_WITH_HCP_MUTATION);
};
