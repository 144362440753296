import { useEffect, useRef } from 'react';
import styled from '@emotion/styled/macro';
import { ApolloError } from '@apollo/client';
import {
  Colors,
  Body05,
  Column,
  Row,
  Avatar,
} from '@robinpowered/design-system';
import { useTranslation } from 'react-i18next';
import { getUsersByKeyword_getUsersByKeyword } from './__generated__/getUsersByKeyword';

type PropTypes = {
  id: string;
  loading: boolean;
  users: null | getUsersByKeyword_getUsersByKeyword[];
  error?: ApolloError;
  focusedIndex: number | null;
  onSelectUser: (user: getUsersByKeyword_getUsersByKeyword) => void;
};

export const HostSelectResults = ({
  id,
  loading,
  users,
  error,
  focusedIndex,
  onSelectUser,
}: PropTypes): JSX.Element => {
  const { t } = useTranslation('hostSelect');

  const focusedRef = useRef<HTMLButtonElement>(null);
  useEffect(() => {
    focusedRef.current?.scrollIntoView({ block: 'end' });
  }, [focusedIndex]);

  return (
    <List id={id} role="listbox" aria-required>
      {loading && !users ? (
        <Row px="4" py="2" role="progressbar">
          {t('loading')}
        </Row>
      ) : users && !users?.length ? (
        <Row px="4" py="2">
          {t('no_results')}
        </Row>
      ) : error ? (
        <Row px="4" py="2">
          {t('error')}
        </Row>
      ) : (
        (users ?? []).map((user, index) => {
          const isFocused = index === focusedIndex;

          return (
            <ListItem
              ref={isFocused ? focusedRef : undefined}
              aria-selected={isFocused}
              focused={isFocused}
              role="option"
              tabIndex={-1}
              type="button"
              key={user.id}
              onClick={() => onSelectUser(user)}
            >
              <Row>
                <Column mr="16px">
                  <Avatar
                    // Names can be empty strings. Avatar throws up empty strings.
                    name={user.name || user?.primaryEmail?.email || 'unknown'}
                    src={user.avatar ?? undefined}
                    size={36}
                  />
                </Column>
                <Column overflow="hidden" justifyContent="center">
                  {user.name && (
                    <Body05
                      style={{
                        color: 'currentcolor',
                        fontSize: 22,
                        lineHeight: '26px',
                      }}
                    >
                      {user.name}
                    </Body05>
                  )}
                  <EmailContainer>
                    <Body05
                      style={isFocused ? { color: 'currentcolor' } : undefined}
                    >
                      {user.primaryEmail?.email}
                    </Body05>
                  </EmailContainer>
                </Column>
              </Row>
            </ListItem>
          );
        })
      )}
    </List>
  );
};

const List = styled.div`
  position: absolute;
  bottom: calc(100% + 8px);
  left: 0;
  background-color: ${Colors.White0};
  border-radius: 8px;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.15), 0px 1px 12px rgba(0, 0, 0, 0.06);
  padding: 8px 0;
  width: 100%;
  max-height: 250px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  z-index: 1;
`;

const ListItem = styled.button<{ focused: boolean }>`
  flex: 1;
  padding: 8px 24px;
  margin: 0;
  cursor: pointer;
  background: none;
  border: none;
  text-align: left;
  color: ${Colors.Gray100};

  ${(props) =>
    !props.focused &&
    `
    &:hover {
      background-color: ${Colors.Tan5};
    }
  `}

  ${(props) =>
    props.focused &&
    `
    background-color: ${Colors.Magenta100};
    color: ${Colors.White0};
  `}
`;

const EmailContainer = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
