import React, { useRef, useEffect } from 'react';

/**
 * A component which calls the callback when a click happens outside the UI.
 */
export const HandleOutsideClicks: React.FC<
  {
    callback: () => void;
  } & React.ComponentPropsWithoutRef<'div'>
> = ({ callback, ...props }) => {
  const dialogRef = useRef<HTMLDivElement | null>(null);
  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const handleClickOutside = (event: any) => {
      if (!dialogRef?.current?.contains(event.target)) {
        callback();
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [dialogRef, callback]);
  return <div ref={dialogRef} {...props} />;
};
