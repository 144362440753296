import React from 'react';
import styled from '@emotion/styled/macro';
import { Box, Colors } from '@robinpowered/design-system';
import {
  CenteredContent,
  Footer,
  LoaderLarge,
  UnauthedServerError,
} from 'components';
import { ApolloError } from '@apollo/client';

type ContentProps = {
  isLoading?: boolean;
  error?: ApolloError;
  refetch?: () => void;
  renderLoading?: () => JSX.Element;
  mainContentStyle?: React.CSSProperties;
  boxStyle?: React.CSSProperties;
  style?: React.CSSProperties;
};

export const ContentWrapper: React.FC<ContentProps> = ({
  children,
  isLoading = false,
  error,
  refetch = () => {},
  renderLoading,
  boxStyle,
  mainContentStyle,
  style,
}) => {
  if (error) {
    return <UnauthedServerError onPressRetry={refetch} />;
  }

  if (isLoading) {
    return (
      <CenteredContent style={style}>
        <MainContent>
          <BoxWrapper style={boxStyle}>
            {renderLoading ? renderLoading() : <LoaderLarge />}
          </BoxWrapper>
        </MainContent>
        <Footer />
      </CenteredContent>
    );
  }

  return (
    <CenteredContent style={style}>
      <MainContent style={mainContentStyle}>
        <BoxWrapper style={boxStyle}>{children}</BoxWrapper>
      </MainContent>
      <Footer />
    </CenteredContent>
  );
};

export const MainContent = styled.section`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 75px 66px;
`;

export const BoxWrapper = styled(Box)`
  border-radius: 24px;
  padding: 48px;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.08);
  background-color: ${Colors.White0};
`;
