import styled from '@emotion/styled/macro';
import { useTranslation } from 'react-i18next';

const DEFAULT_SIZE = 72;

type PropTypes = {
  name: string;
  imageSource: string;
  size?: number;
};

export const Avatar = ({
  name,
  imageSource,
  size = DEFAULT_SIZE,
}: PropTypes): JSX.Element => {
  const { t } = useTranslation('common');

  return (
    <Logo
      alt={`${name}`}
      src={imageSource}
      aria-label={t('photo_of_x', { name })}
      size={size}
    />
  );
};

const Logo = styled.img<{ size: number }>`
  height: ${({ size }) => `${size}px`};
  width: ${({ size }) => `${size}px`};
  border-radius: 50%;
`;
