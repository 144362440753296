import { useTranslation } from 'react-i18next';
import { Box } from '@robinpowered/design-system';
import { GetGuestVisitById_getGuestVisitById } from 'hooks/__generated__/GetGuestVisitById';
import { Field, FieldLabel } from 'components';
import { formatStartTimeMessage } from 'utils';

export const VisitFields = ({
  visit,
}: {
  visit: GetGuestVisitById_getGuestVisitById;
}): JSX.Element => {
  const { t } = useTranslation('visitDetails');

  return (
    <>
      <Field>
        <FieldLabel>{t('host')}</FieldLabel>
        {visit.host.name ?? t('unknown_host')}
      </Field>
      <Field>
        <FieldLabel>{t('where')}</FieldLabel>
        {visit.arrivalLocation.name}
        {visit.arrivalLocation.address && (
          <Box mt={1}>{visit.arrivalLocation.address}</Box>
        )}
      </Field>
      <Field>
        <FieldLabel>{t('when')}</FieldLabel>
        {formatStartTimeMessage(
          visit.startTime,
          visit.arrivalLocation.timezone,
          true
        )}
      </Field>
      <Field last>
        <FieldLabel>{t('why')}</FieldLabel>
        {visit.customVisitType}
      </Field>
    </>
  );
};
