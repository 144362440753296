import { gql } from 'graphql-tag';
import {
  useMutation,
  MutationTuple,
  MutationHookOptions,
} from '@apollo/client';
import {
  CheckIntoSelfRegisteredVisitFromArrivalDisplay,
  CheckIntoSelfRegisteredVisitFromArrivalDisplayVariables,
} from './__generated__/CheckIntoSelfRegisteredVisitFromArrivalDisplay';

const CHECK_IN_MUTATION = gql`
  mutation CheckIntoSelfRegisteredVisitFromArrivalDisplay($guestInviteId: ID!) {
    checkIntoSelfRegisteredVisitFromArrivalDisplay(
      guestInviteId: $guestInviteId
    )
  }
`;

/**
 * Checks in a visit that was self-registered on the Arrival Display.
 *
 * For checking in visits created in advance, use `useCheckInPreRegVisit`
 */
export const useCheckInSelfRegVisit = (
  options?: MutationHookOptions<
    CheckIntoSelfRegisteredVisitFromArrivalDisplay,
    CheckIntoSelfRegisteredVisitFromArrivalDisplayVariables
  >
): MutationTuple<
  CheckIntoSelfRegisteredVisitFromArrivalDisplay,
  CheckIntoSelfRegisteredVisitFromArrivalDisplayVariables
> => {
  return useMutation<
    CheckIntoSelfRegisteredVisitFromArrivalDisplay,
    CheckIntoSelfRegisteredVisitFromArrivalDisplayVariables
  >(CHECK_IN_MUTATION, options);
};
