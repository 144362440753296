import { createContext, ReactNode, useContext } from 'react';

export type Organization = {
  id: string;
  name: string | null;
  logo: string | null;
};

export type Location = {
  id: string;
  name: string;
  isHealthCheckpointRequired: boolean;
};

type DeviceContextValue = {
  org: Organization | undefined;
  location: Location | undefined;
  deviceName: string | undefined;
};

const DeviceContext = createContext<DeviceContextValue | undefined>(undefined);

export const DeviceContextProvider = ({
  org,
  location,
  deviceName,
  children,
}: {
  org?: Organization;
  location?: Location;
  deviceName?: string;
  children: ReactNode;
}): JSX.Element => {
  return (
    <DeviceContext.Provider value={{ org, location, deviceName }}>
      {children}
    </DeviceContext.Provider>
  );
};

export function useDeviceContext(): DeviceContextValue {
  const context = useContext(DeviceContext);
  if (!context) {
    throw new Error(
      'useDeviceContext must be used within a DeviceContextProvider'
    );
  }
  return context;
}
