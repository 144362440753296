import { SyntheticEvent, useState } from 'react';
import styled from '@emotion/styled/macro';
import {
  Heading01,
  Body02Styles,
  InputField,
} from '@robinpowered/design-system';
import { InputWrapper, ArrivalDisplayButton } from 'components';
import { useTranslation } from 'react-i18next';
import { VisitResults } from './VisitResults';

type ModalArgs = {
  onPressCancel: () => void;
};

export const VisitLookup: React.FC<ModalArgs> = ({ onPressCancel }) => {
  const { t } = useTranslation('checkOut');
  const [email, setEmail] = useState('');

  return (
    <form
      noValidate
      onSubmit={(event) => {
        event.preventDefault(); // Don't redirect page
      }}
    >
      <Heading01 style={{ fontSize: '30px' }} mb="24px">
        {t('visit_lookup.title')}
      </Heading01>

      <Label>
        {t('visit_lookup.subtitle')}
        <InputWrapper>
          <InputField
            id="email-input"
            type="email"
            required
            autoFocus // eslint-disable-line jsx-a11y/no-autofocus
            autoComplete="off"
            onChange={(event: SyntheticEvent<HTMLInputElement>) => {
              setEmail(event.currentTarget.value);
            }}
            value={email}
            containerStyle={{
              marginTop: '16px',
            }}
            style={{
              fontSize: '26px',
              padding: '20px 18px', // Will get 6px horizontal padding
            }}
          />
        </InputWrapper>
      </Label>

      {email.length === 0 ? (
        <ArrivalDisplayButton
          style={{ margin: '24px 0 0 auto' }}
          variant="secondary"
          onClick={onPressCancel}
        >
          {t('cancel')}
        </ArrivalDisplayButton>
      ) : (
        <VisitResults
          style={{ margin: '10px 0 -11px 0' }}
          emailSearchString={email}
        />
      )}
    </form>
  );
};

const Label = styled.label`
  ${Body02Styles}
  display: block;
`;
