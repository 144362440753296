import { ApolloError, useMutation, useLazyQuery } from '@apollo/client';
import { useApolloContext, useAmplitude } from 'contexts';
import { GET_ORGANIZATION_QUERY } from 'hooks';
import {
  GetOrganizationQuery,
  GetOrganizationQueryVariables,
} from 'hooks/__generated__/GetOrganizationQuery';
import { gql } from 'graphql-tag';
import { useState } from 'react';
import { useHistory } from 'react-router';
import {
  RegisterArrivalDisplay,
  RegisterArrivalDisplayVariables,
} from './__generated__/RegisterArrivalDisplay';
import { i18n } from 'i18n';
import { useLocalStorage } from 'react-use';

const REGISTER_DISPLAY = gql`
  mutation RegisterArrivalDisplay(
    $organizationId: ID!
    $key: ArrivalDisplayRegistrationKey!
  ) {
    registerArrivalDisplay(organizationId: $organizationId, key: $key) {
      id
      authorization {
        id
        bearerToken
        refreshToken
        organizationId
      }
      device {
        id
        name
        location {
          id
          preferredLanguageTag
        }
      }
      certKey
    }
  }
`;

type UseRegisterDisplay = {
  registerDisplay: (organizationId: string, key: string) => Promise<void>;
  loading: boolean;
  error: Error | undefined;
};

const LOCAL_STORAGE_LANGUAGE_KEY = '_rbn-lang-flag';

export const useRegisterDisplay = (): UseRegisterDisplay => {
  const [error, setError] = useState<Error | undefined>();
  const { setAuthTokens, setTenantId, setCertKey } = useApolloContext();
  const { identify: identifyAmplitude, trackDeviceRegistered } = useAmplitude();
  const history = useHistory();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, setSelectedLanguageSlug] = useLocalStorage<string>(
    LOCAL_STORAGE_LANGUAGE_KEY
  );

  const [registerDeviceMutation, { loading }] = useMutation<
    RegisterArrivalDisplay,
    RegisterArrivalDisplayVariables
  >(REGISTER_DISPLAY);

  const [getOrganization, { data: orgData }] = useLazyQuery<
    GetOrganizationQuery,
    GetOrganizationQueryVariables
  >(GET_ORGANIZATION_QUERY);

  const registerDisplay = async (organizationId: string, key: string) => {
    // clear out any existing errors in the case of a retry
    setError(undefined);

    try {
      const variables = { organizationId, key };
      const result = await registerDeviceMutation({ variables });
      const auth = result?.data?.registerArrivalDisplay?.authorization;
      const certKey = result?.data?.registerArrivalDisplay?.certKey;
      const device = result?.data?.registerArrivalDisplay?.device;
      const preferredLanguageTag = device?.location?.preferredLanguageTag;

      if (auth) {
        setAuthTokens(auth.bearerToken, auth.refreshToken);
        setTenantId(auth.organizationId);

        if (!certKey) {
          throw new Error('Cert key missing from response');
        }

        setCertKey(certKey);

        if (preferredLanguageTag) {
          i18n.changeLanguage(preferredLanguageTag);
          setSelectedLanguageSlug(preferredLanguageTag);
        }

        // Don't await. Avoids potential edge condition where we're
        // authenticated but stuck. Deal with `getOrganization` failures in `useAuthentication`.
        getOrganization({
          variables: {
            organizationId: auth?.organizationId ?? '',
          },
        });

        // Track that the device is registered
        identifyAmplitude(
          auth.organizationId,
          orgData?.getOrganizationById?.slug ?? 'unknown'
        );
        trackDeviceRegistered(
          result?.data?.registerArrivalDisplay?.device.name ?? 'unknown'
        );
        history.push('/');
      } else {
        throw new Error('Auth tokens missing from the response');
      }
    } catch (err) {
      // Device doesn't exist or has already been registered
      const isRegistrationError =
        err instanceof ApolloError &&
        err.graphQLErrors?.[0]?.extensions?.code === 'UNAUTHENTICATED';

      if (isRegistrationError) {
        // TODO: make this error message work
        history.push(
          `/instructions?error=device+doesnt+exist+or+is+already+registered`
        );
      } else {
        setError(err as Error);
      }
    }
  };

  return {
    registerDisplay,
    loading,
    error,
  };
};
