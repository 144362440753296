import styled from '@emotion/styled/macro';
import { ErrorBadge } from '@robinpowered/design-system';

type PropTypes = {
  message: string;
};

export const BoundedErrorBadge = ({ message }: PropTypes): JSX.Element => (
  <ErrorWrapper>
    <ErrorBadge politenessSetting="polite" message={message} />
  </ErrorWrapper>
);

const ErrorWrapper = styled.div`
  margin: 17px auto -57px;
  padding: 0 66px;
  max-width: 768px;
  width: 100%;
  z-index: 1;
`;
