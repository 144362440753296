import {
  Avatar,
  Body03,
  Body04,
  Box,
  Colors,
  Heading02,
  Row,
} from '@robinpowered/design-system';
import {
  ArrivalDisplayButton,
  CenteredContent,
  Header,
  MainContent,
  Footer,
  BoxWrapper,
  PrintableBadge,
} from 'components';
import { useTranslation } from 'react-i18next';
import { useTimedRedirect } from 'hooks';
import { useHistory } from 'react-router';
import styled from '@emotion/styled/macro';
import { useDeviceContext, useVisitContext } from 'contexts';
import { useEffect, useState } from 'react';

const REDIRECT_SECONDS = 1000 * 60 * 2; // 2 minutes

export const BadgePrint = (): JSX.Element => {
  useTimedRedirect('/', REDIRECT_SECONDS);
  const { t } = useTranslation('badgePrint');
  const [hasPrintDialogShown, setHasPrintDialogShown] =
    useState<boolean>(false);
  const history = useHistory();
  const { visit } = useVisitContext();
  const { location } = useDeviceContext();

  const handleAfterPrint = () => {
    setHasPrintDialogShown(true);
  };

  useEffect(() => {
    window.addEventListener('afterprint', handleAfterPrint);
    return () => window.removeEventListener('afterprint', handleAfterPrint);
  }, []);

  return (
    <PageContainer>
      <PrintableBadge visit={visit} />

      <PrintWrapper>
        <Header
          backHidden
          nextHidden={!hasPrintDialogShown}
          onPrevClick={() => {}}
          nextButtonText={t('next_button')}
          onNextClick={() => history.push('/')}
        />

        <CenteredContent>
          <MainContent>
            <BoxWrapper mb="24px">
              <Heading02 mb={2} style={{ fontSize: 30, lineHeight: '36px' }}>
                {t('heading')}
              </Heading02>
              <Body03 mb="24px" color={Colors.Gray80}>
                {t('sub_heading')}
              </Body03>
              <ArrivalDisplayButton
                style={{ padding: '16px 32px' }}
                onClick={window.print}
                variant={hasPrintDialogShown ? 'secondary' : 'primary'}
              >
                {t('print_button')}
              </ArrivalDisplayButton>
            </BoxWrapper>
            <HostMessageBox>
              <Heading02 mb={3}>
                {visit?.host.name
                  ? t('host_message_heading', { host: visit.host.name })
                  : t('missing_host_name')}
              </Heading02>
              <Row alignItems="center" justifyContent="space-between">
                <Body04>
                  {t('host_message_body', { displayName: location?.name })}
                </Body04>
                {visit?.host.avatar && (
                  <AvatarWrapper>
                    <Avatar
                      name={t('host_avatar_name')}
                      src={visit.host.avatar}
                      size={48}
                    />
                  </AvatarWrapper>
                )}
              </Row>
            </HostMessageBox>
          </MainContent>
          <Footer />
        </CenteredContent>
      </PrintWrapper>
    </PageContainer>
  );
};

const HostMessageBox = styled(Box)`
  background-color: ${Colors.Tan30};
  border-radius: 24px;
  padding: 32px 48px;
`;

const PrintWrapper = styled.div`
  display: flex;
  flex-direction: column;

  @media print {
    display: none;
  }
`;

const PageContainer = styled.div`
  display: flex;
  flex: 1;
`;

const AvatarWrapper = styled.div`
  margin-left: 64px;
  min-width: 48px;
`;
