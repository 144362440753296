import styled from '@emotion/styled/macro';
import { Button } from '@robinpowered/design-system';

export const WelcomeButton = styled(Button)`
  border-radius: 16px;
  height: auto;
  font-size: 26px;
  padding: 20px;
  min-width: 196px;
  width: auto;
`;
