import { useEffect, useState } from 'react';
import {
  Router,
  Route,
  Switch,
  Redirect,
  useLocation,
  useRouteMatch,
  useParams,
} from 'react-router-dom';
import {
  BadgePrint,
  CheckIn,
  CheckInCompleted,
  HealthCheckpoint,
  HealthCheckpointChecking,
  GuestLookup,
  GuestRegistrationDetails,
  VisitSelection,
  VisitDetails,
  Welcome,
  RegisterDevice,
  RegisterInstructions,
  GuestRegistrationVisitType,
  GuestRegistrationHcpLoading,
  GuestRegistrationHcp,
  GuestRegistrationDocumentAgreement,
  GuestRegistrationCheckIn,
  DocumentAgreement,
} from './pages';
import {
  useAuthentication,
  useLogRocket,
  useSelfRegistrationEnabled,
} from 'hooks';
import {
  AppLoader,
  UnauthedServerError,
  UnauthorizedErrorModal,
} from 'components';
import {
  VisitContextProvider,
  DeviceContextProvider,
  SelfRegisteredGuestContextProvider,
  BadgePrintingContextProvider,
} from 'contexts';
import { history } from 'lib/history';
import { useHistory } from 'react-router';
import LogRocket from 'logrocket';
import { usePrevious } from 'react-use';
import { Sentry } from 'lib/sentry';

function ScrollToTop() {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return null;
}

const { REACT_APP_RELEASE_VERSION: RELEASE_VERSION } = process.env;

const AuthenticatedRoutes = () => {
  const {
    authenticate,
    loading,
    error,
    isAuthenticated,
    organization,
    location,
    deviceName,
    deviceId,
  } = useAuthentication();
  const wasAuthenticated = usePrevious(isAuthenticated);
  const routerHistory = useHistory();
  const [shouldUnauthorizedModalShow, setShouldUnauthorizedModalShow] =
    useState<boolean>(false);

  useEffect(() => {
    authenticate();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // when we have access to an org ID, pass that information on to LR
  useEffect(() => {
    if (!organization || !organization.id) {
      return;
    }
    LogRocket.identify({
      orgId: organization.id,
    });
  }, [organization]);

  useEffect(() => {
    if (wasAuthenticated && !isAuthenticated) {
      Sentry.captureMessage(
        'Transition from authenticated to unauthenticated. Possibly deleted by admin',
        {
          level: Sentry.Severity.Debug,
          extra: {
            appVersion: RELEASE_VERSION,
            deviceName: deviceName,
            deviceId: deviceId,
            location: location?.name,
            orgName: organization?.name,
            orgSlug: organization?.slug,
          },
        }
      );

      setShouldUnauthorizedModalShow(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wasAuthenticated, isAuthenticated, routerHistory]);

  if (loading) {
    return <AppLoader />;
  }
  if (error) {
    return <UnauthedServerError onPressRetry={() => authenticate()} />;
  }

  // Only redirect if the unauthorized modal is not showing. There are multiple
  // renders when the authenticated state goes from true to false, which prevent
  // the modal from showing without the additional checks in this if statement.
  if (!wasAuthenticated && !isAuthenticated && !shouldUnauthorizedModalShow) {
    Sentry.captureMessage(
      'Unauthenticated. Returning to instructions screen.',
      {
        level: Sentry.Severity.Debug,
        extra: {
          appVersion: RELEASE_VERSION,
          deviceName: deviceName,
          deviceId: deviceId,
          location: location?.name,
          orgName: organization?.name,
          orgSlug: organization?.slug,
        },
      }
    );
    return <Redirect to="/instructions" />;
  }

  return (
    <DeviceContextProvider
      org={organization}
      location={location}
      deviceName={deviceName}
    >
      <BadgePrintingContextProvider>
        {shouldUnauthorizedModalShow && (
          <UnauthorizedErrorModal
            onPress={() => routerHistory.push('/instructions')}
          />
        )}

        <Switch>
          <Route path="/" exact>
            <Welcome />
          </Route>
          <Route path="/guest-lookup" exact>
            <GuestLookup />
          </Route>
          <Route path="/visit-selection" exact>
            <VisitSelection />
          </Route>
          <Route path="/guest-registration">
            <GuestSelfRegistrationRoutes />
          </Route>
          <Route path="/:visitId">
            <VisitRoutes />
          </Route>
          <Route>
            <Welcome />
          </Route>
        </Switch>
      </BadgePrintingContextProvider>
    </DeviceContextProvider>
  );
};

const VisitRoutes = (): JSX.Element => {
  const { path } = useRouteMatch();
  const { visitId } = useParams<{ visitId: string }>();

  return (
    <VisitContextProvider initialVisitId={visitId}>
      <Switch>
        <Route path={`${path}/visit-details`} exact>
          <VisitDetails />
        </Route>
        <Route path={`${path}/health-checkpoint`} exact>
          <HealthCheckpoint />
        </Route>
        <Route path={`${path}/check-in`} exact>
          <CheckIn />
        </Route>
        <Route path={`${path}/health-checkpoint-checking`} exact>
          <HealthCheckpointChecking />
        </Route>
        <Route
          path={[
            `${path}/document-agreement`,
            `${path}/document-agreement/:documentId`,
          ]}
          exact
        >
          <DocumentAgreement />
        </Route>
        <Route path={`${path}/badge-print`} exact>
          <BadgePrint />
        </Route>
        <Route path={`${path}/completed`} exact>
          <CheckInCompleted />
        </Route>
        <Route>
          <Welcome />
        </Route>
      </Switch>
    </VisitContextProvider>
  );
};

const GuestSelfRegistrationRoutes = (): JSX.Element => {
  const { path } = useRouteMatch();
  const { data } = useSelfRegistrationEnabled();

  if (!data?.isGuestSelfRegistrationEnabled) {
    return <Redirect to="/" />;
  }

  return (
    <Switch>
      <Redirect from={path} to={`${path}/details`} exact />
      <SelfRegisteredGuestContextProvider>
        <VisitContextProvider>
          <Route path={`${path}/details`} exact>
            <GuestRegistrationDetails />
          </Route>
          <Route path={`${path}/visit-type`} exact>
            <GuestRegistrationVisitType />
          </Route>
          <Route path={`${path}/health-checkpoint-loading`} exact>
            <GuestRegistrationHcpLoading />
          </Route>
          <Route path={`${path}/health-checkpoint`} exact>
            <GuestRegistrationHcp />
          </Route>
          <Route
            path={[
              `${path}/document-agreement`,
              `${path}/document-agreement/:documentId`,
            ]}
            exact
          >
            <GuestRegistrationDocumentAgreement />
          </Route>
          <Route path={`${path}/check-in`} exact>
            <GuestRegistrationCheckIn />
          </Route>
          <Route path={`${path}/health-checkpoint-checking`} exact>
            <HealthCheckpointChecking />
          </Route>
          <Route path={`${path}/completed`} exact>
            <CheckInCompleted />
          </Route>
          <Route path={`${path}/badge-print`} exact>
            <BadgePrint />
          </Route>
        </VisitContextProvider>
      </SelfRegisteredGuestContextProvider>
    </Switch>
  );
};

export const AppRouter = (): JSX.Element => {
  // initialize LogRocket session recording here to capture all user paths
  useLogRocket();

  return (
    <Router history={history}>
      <ScrollToTop />
      <Switch>
        <Route path="/:organizationId(\d+)/:registrationKey(\d+)" exact>
          <RegisterDevice />
        </Route>
        <Route path="/instructions" exact>
          <RegisterInstructions />
        </Route>
        <Route path="/">
          <AuthenticatedRoutes />
        </Route>
        <Route component={RegisterInstructions} />
      </Switch>
    </Router>
  );
};
