import { useEffect, useState } from 'react';

const MIN_LOADING_TIME = 1000; // ms (1 second)

/**
 * A wrapper for a boolean loading state, which ensures a minimum loading time.
 * If isLoading is true for less than the minimum time, it is returned false only
 * after the minimum time has elapsed.
 *
 * This can be used to avoid jarring UX where too-fast interactions can feel jarring
 * and unexpected. Sometimes it feels better if a loading indicator shows for a full
 * second rather than briefly flashing for 50ms.
 *
 * Example:
 * const {data, loading: isLoadingChairs} = useChairs();
 * const isLoading = useMinimumLoading(isLoadingChairs);
 *
 * @param isLoading Whether the actual network call is loading.
 * @param minLoadingTimeMillis The minimum loading time.
 * @returns The isLoading boolean, with any "true" value buffered by a minimum time.
 */
export const useMinimumLoading = (
  isLoading: boolean,
  minLoadingTimeMillis = MIN_LOADING_TIME
): boolean => {
  const [isLoadingInternal, setIsLoadingInternal] = useState(isLoading);
  const [minTimeElapsed, setMinTimeElapsed] = useState(true);

  useEffect(() => {
    if (isLoading && !isLoadingInternal) {
      // UI has started loading. Show loading indicator and start timer for the min loading time.
      setIsLoadingInternal(true);
      setMinTimeElapsed(false);
      setTimeout(() => setMinTimeElapsed(true), minLoadingTimeMillis);
    } else if (!isLoading && isLoadingInternal && !minTimeElapsed) {
      // UI has finished loading, for less than the minimum time. Keep loading until min time elapses.
      // Do nothing.
    } else if (!isLoading && isLoadingInternal && minTimeElapsed) {
      // Either:
      //  - UI has finished loading, for more than the minimum time. Hide loading indicator.
      //  - UI already finished loading, min time just elapsed. Now hide the loading indicator.
      setIsLoadingInternal(false);
    }
  }, [isLoading, minTimeElapsed]); // eslint-disable-line react-hooks/exhaustive-deps

  return isLoadingInternal;
};
