import { Document, FullPageError, Header } from 'components';
import {
  useDeviceContext,
  usePageLoadedEvent,
  useSelfRegisteredGuestContext,
  useVisitContext,
} from 'contexts';
import { useTimedRedirect } from 'hooks';
import { useHistory, useParams } from 'react-router';
import styled from '@emotion/styled/macro';
import { SpinnerLoader } from '@robinpowered/design-system';

export const GuestRegistrationDocumentAgreement = (): JSX.Element => {
  const history = useHistory();
  const { documentId } = useParams<{ documentId: string }>();
  const { location } = useDeviceContext();
  const {
    visit,
    loading: visitLoading,
    refetch: visitRefetch,
  } = useVisitContext();
  const { guestName, guestVisitType, visitId, guestInviteId } =
    useSelfRegisteredGuestContext();
  const previousURL = location?.isHealthCheckpointRequired
    ? `/${guestInviteId}/visit-details`
    : '/guest-registration/visit-type';

  usePageLoadedEvent('self-registration-document-agreement', false);
  useTimedRedirect();

  if (!visit && visitLoading) {
    return (
      <>
        <Header
          onPrevClick={() => {
            history.push(previousURL);
          }}
          onNextClick={() => {}}
          nextHidden
        />
        <LoaderWrapper>
          <SpinnerLoader size={32} />
        </LoaderWrapper>
      </>
    );
  }

  if (!visit || !guestVisitType || !location) {
    return (
      <FullPageError
        onPressRetry={() => {
          visitRefetch && visitRefetch();
        }}
        retryLoading={visitLoading}
      />
    );
  }

  return (
    <Document
      visitType={guestVisitType}
      locationId={location.id}
      visitId={visitId}
      inviteId={guestInviteId}
      guestId={visit.guest.id}
      documentId={documentId}
      previousURL={previousURL}
      nextDocumentURL={`/guest-registration/document-agreement`}
      guestName={guestName}
      completeCheckIn={() => history.push(`/guest-registration/check-in`)}
    />
  );
};

const LoaderWrapper = styled.div`
  align-items: center;
  justify-content: center;
  display: flex;
  flex: 1;
`;
