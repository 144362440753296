import styled from '@emotion/styled/macro';
import { Button, Colors } from '@robinpowered/design-system';
import CircleWarning from '@robinpowered/icons/v2/CircleWarning';
import { useTranslation } from 'react-i18next';

type PropTypes = {
  message: string;
  onRetry: () => void;
};

export const InlineError = ({ message, onRetry }: PropTypes): JSX.Element => {
  const { t } = useTranslation('common');

  return (
    <ErrorWrapper>
      <CircleWarning size={24} color={Colors.RedProduct} />
      {message}
      <Button variant="secondary" ml={2} onClick={onRetry}>
        {t('try_again')}
      </Button>
    </ErrorWrapper>
  );
};

const ErrorWrapper = styled.div`
  align-items: center;
  display: flex;
  color: ${Colors.RedProduct};
  padding: 24px 16px;
`;
