import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
} from 'react';
import Amplitude, { AmplitudeClient, Identify } from 'amplitude-js';

type AmplitudeContextValue = {
  identify: (orgId: string, organizationSlug: string) => void;
  trackEvent: (eventName: string, metadata?: Record<string, unknown>) => void;
};

const defaultFn = () => {
  if (process.env.NODE_ENV === 'development') {
    // eslint-disable-next-line no-console
    console.warn('Tried to called a method before AmplitudeContext is ready');
  }
};

const AmplitudeContext = createContext<AmplitudeContextValue>({
  identify: defaultFn,
  trackEvent: defaultFn,
});

const log = (message: string, ...args: unknown[]) => {
  if (process.env.NODE_ENV === 'development') {
    console.log(`[Amplitude] ${message}`, ...args); // eslint-disable-line no-console
  }
};

export const AmplitudeProvider = ({
  children,
}: {
  children: JSX.Element;
}): JSX.Element => {
  const client = useMemo<AmplitudeClient>(() => {
    const client = Amplitude.getInstance('robin-product-metrics');
    const apiKey = process.env.REACT_APP_AMPLITUDE_API_KEY;
    if (apiKey) {
      client.init(apiKey);
    } else {
      log('Could not initialize; API key not found');
    }
    return client;
  }, []);

  const trackEvent = useCallback(
    (eventName: string, metadata?: Record<string, unknown>) => {
      log('Tracking event:', { eventName, metadata });
      client.logEvent(eventName, metadata);
    },
    [client]
  );

  const identify = useCallback(
    (orgId, organizationSlug) => {
      const identify = new Identify();
      identify.set('organizationId', orgId);
      identify.set('organizationSlug', organizationSlug);
      client.setGroup('organizationId', orgId);
      client.setGroup('organizationSlug', organizationSlug);
      log('Setting user info:', { orgId, organizationSlug });
      client.identify(identify);
      log('Client identity set');
    },
    [client]
  );

  const api = {
    identify,
    trackEvent,
  };

  return (
    <AmplitudeContext.Provider value={api}>
      {children}
    </AmplitudeContext.Provider>
  );
};

type Page =
  // common pages
  | 'register-display'
  | 'register-instructions'
  | 'welcome'

  // check-in registration pages
  | 'guest-lookup'
  | 'select-visit'
  | 'visit-details'
  | 'health-checkpoint'
  | 'document-agreement'
  | 'check-in-completed'

  // self registration pages
  | 'self-registration-details'
  | 'self-registration-completed'
  | 'self-registration-health-checkpoint-loading'
  | 'self-registration-health-checkpoint'
  | 'self-registration-visit-type'
  | 'self-registration-document-agreement';

type UseAmplitude = () => {
  identify: (orgId: string, organizationSlug: string) => void;
  trackDeviceRegistered: (deviceName: string) => void;
  trackPageLoaded: (page: Page, isCheckedIn: boolean) => void;
  trackEmailLookup: () => void;
};

export const useAmplitude: UseAmplitude = () => {
  const { identify, trackEvent } = useContext(AmplitudeContext);

  const trackDeviceRegistered = (deviceName: string) => {
    trackEvent('registered-org-with-guest-kiosk', { deviceName });
  };

  const trackPageLoaded = (page: Page, isCheckedIn: boolean) => {
    trackEvent('page-loaded-guest-kiosk', { page, isCheckedIn });
  };

  const trackEmailLookup = () => {
    trackEvent('email-lookup-guest-kiosk');
  };

  return {
    identify,
    trackDeviceRegistered,
    trackPageLoaded,
    trackEmailLookup,
  };
};

export const usePageLoadedEvent = (page: Page, isCheckedIn: boolean): void => {
  const { trackPageLoaded } = useAmplitude();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => trackPageLoaded(page, isCheckedIn), [page]);
};
