import styled from '@emotion/styled/macro';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import {
  LanguageSelector,
  LanguageSelectorContainer,
  WelcomeButton,
} from './components';
import { Billboard, CompanyLogo, Footer } from 'components';
import { useEffect, useState } from 'react';
import {
  useBadgePrintingContext,
  useDeviceContext,
  usePageLoadedEvent,
} from 'contexts';
import { useAutomaticPageReload } from './hooks';
import { Row } from '@robinpowered/design-system';
import { CheckOutModal } from './components/CheckOutModal';
import { CheckOutContextProvider } from './components/CheckOutModal/CheckOutContext';

export const Welcome = (): JSX.Element => {
  const { t } = useTranslation('welcome');
  const history = useHistory();
  const { org } = useDeviceContext();
  const { setPrinterIpAddress } = useBadgePrintingContext();
  const [showCheckOutModal, setShowCheckOutModal] = useState(false);

  usePageLoadedEvent('welcome', false);
  useAutomaticPageReload();

  useEffect(() => {
    setPrinterIpAddress(null);
  }, [setPrinterIpAddress]);

  return (
    <ScreenBackground>
      <WelcomeHeader>
        {org?.logo && (
          <CompanyLogo companyName={org?.name} imageSource={org.logo} />
        )}
        <LanguageSelector />
      </WelcomeHeader>
      <MainContent>
        <Heading>
          {org?.name
            ? t('intro', {
                company: org.name,
              })
            : t('intro_sans_org')}
        </Heading>
        <Row alignItems="center">
          <WelcomeButton
            aria-label={t('check_in_aria_label')}
            variant="primary"
            size="large"
            name="check-in"
            onClick={() => history.push('guest-lookup')}
          >
            {t('check_in')}
          </WelcomeButton>
          <WelcomeButton
            style={{ marginLeft: '16px' }}
            aria-label={t('check_out')}
            variant="secondary"
            size="large"
            name="check-out"
            onClick={() => setShowCheckOutModal(true)}
          >
            {t('check_out')}
          </WelcomeButton>
        </Row>
      </MainContent>
      <Footer />
      {showCheckOutModal && (
        <CheckOutContextProvider>
          <CheckOutModal closeModal={() => setShowCheckOutModal(false)} />
        </CheckOutContextProvider>
      )}
    </ScreenBackground>
  );
};

const ScreenBackground = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 64px 64px 0;
  width: 100%;
`;

const WelcomeHeader = styled.header`
  display: flex;
  justify-content: space-between;

  ${LanguageSelectorContainer} {
    margin-left: auto;
  }
`;

const MainContent = styled.section`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  // Use nearly the whole width for portrait-oriented displays
  // Constrain to a smaller box for landscape-oriented displays
  width: min(140vh, 90vw);
`;

const Heading = styled(Billboard)`
  margin: 48px 0;
`;
