/* eslint-disable global-require */
/* eslint-disable  @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-var-requires */
import { useEffect } from 'react';
import LogRocket from 'logrocket';

// Importing using require due to poor typings: https://github.com/LogRocket/logrocket-fuzzy-search-sanitizer
// eslint-disable-next-line @typescript-eslint/no-var-requires
const LogrocketFuzzySanitizer =
  require('logrocket-fuzzy-search-sanitizer') as unknown as any;

// Add any private field names that should be scrubbed from the request or response.
const PRIVATE_FIELD_NAMES: Array<string> = ['bearerToken', 'refreshToken'];

/**
 * Hook used to initialize LogRocket
 */
export const useLogRocket = (): void => {
  const { requestSanitizer, responseSanitizer } =
    LogrocketFuzzySanitizer.setup(PRIVATE_FIELD_NAMES);

  useEffect(() => {
    if (
      process.env.NODE_ENV !== 'production' ||
      !process.env.REACT_APP_LOGROCKET_ID
    ) {
      return;
    }

    LogRocket.init(process.env.REACT_APP_LOGROCKET_ID, {
      dom: {
        inputSanitizer: true, // obfuscate any inputs
      },
      network: {
        requestSanitizer,
        responseSanitizer,
      },
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
