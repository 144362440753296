import styled from '@emotion/styled/macro';
import { Heading01 } from '@robinpowered/design-system';

/**
 * A header text style unique to the Welcome Display.
 *
 * color: N700; fontSize: 30px; fontWeight: 500
 *
 * For design details, check out Figma:
 * https://www.figma.com/file/gsgNy3hPBViS6x1JW6dyye/Visitor-checks-in?node-id=3053%3A48794
 */
export const Heading00 = styled(Heading01)`
  font-size: 30px;
  line-height: 37.5px;
`;
