import { AppRouter } from './AppRouter';
import { AmplitudeProvider, ApolloProvider } from './contexts';
import { ErrorBoundary, NoInternet } from 'components';
import './styles.css';
import { RKTheme } from '@robinpowered/design-system';

function App(): JSX.Element {
  return (
    <RKTheme>
      <ErrorBoundary>
        <ApolloProvider>
          <NoInternet />
          <AmplitudeProvider>
            <AppRouter />
          </AmplitudeProvider>
        </ApolloProvider>
      </ErrorBoundary>
    </RKTheme>
  );
}

export default App;
