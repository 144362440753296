import { useState, useEffect } from 'react';
import {
  Heading01,
  Body05,
  Dialog,
  Colors,
  Row,
} from '@robinpowered/design-system';
import { createPortal } from 'react-dom';
import { useTranslation } from 'react-i18next';
import MinorWarning from '@robinpowered/icons/v2/MinorWarningSolid';
import styled from '@emotion/styled';

/**
 * This component works with our service worker (src/service-worker.js)
 * to indicate to the user when there is a network or internet connectivity error
 */
export const NoInternet = (): JSX.Element | null => {
  const { t } = useTranslation('noInternet');
  const [isInternetOut, setIsInternetOut] = useState<boolean>(false);

  // 👨‍💻🙅‍😱 oh no! internet is out!
  useEffect(() => {
    const setInternet = () => setIsInternetOut(true);
    window.addEventListener('offline', setInternet);
    return () => window.removeEventListener('offline', setInternet);
  }, []);

  // 🦄🎊🔥 we're back online!
  useEffect(() => {
    const setInternet = () => setIsInternetOut(false);
    window.addEventListener('online', setInternet);
    return () => window.removeEventListener('online', setInternet);
  }, []);

  if (!isInternetOut) {
    return null;
  }

  return createPortal(
    <>
      <Dialog.Overlay style={{ backgroundColor: 'rgba(0,0,0,0.3)' }} />
      <Dialog style={{ minWidth: '425px' }} {...undefined}>
        <Heading01
          mb={3}
          style={{
            fontSize: '22px',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <MinorWarning
            size={24}
            color={Colors.RedProduct}
            style={{ marginRight: '4px' }}
          />
          {t('heading')}
        </Heading01>
        <Body05 color={Colors.Gray40} mb={3} mr={2}>
          {t('body')}
        </Body05>
        <Row justifyContent="flex-end">
          <SpinnerLoader />
        </Row>
      </Dialog>
    </>,
    document.body
  );
};

// A container that rotates.
// Combine this with the growing-shrinking circle path
// to create the chasing-tail animation effect.
const RotatingSVG = styled.svg`
  animation: 2s linear infinite both rotate_svg;
  @keyframes rotate_svg {
    0% {
      transform: rotateZ(0deg);
    }
    100% {
      transform: rotateZ(360deg);
    }
  }
`;

// A path that traces the outline of a circle.
// Its length grows and shrinks.
const GrowShrinkCirclePath = styled.circle`
  animation: 1.4s ease-in-out infinite both circle_grow_shrink_animation;
  fill: transparent;
  stroke-dasharray: 285;
  stroke-linecap: round;
  transform-origin: 50% 50%;
  @keyframes circle_grow_shrink_animation {
    0%,
    25% {
      stroke-dashoffset: 280;
      transform: rotate(0);
    }
    50%,
    75% {
      stroke-dashoffset: 75;
      transform: rotate(45deg);
    }
    100% {
      stroke-dashoffset: 280;
      transform: rotate(360deg);
    }
  }
`;

/**
 * A loading animation that features a circle outline spinning,
 * chasing its tail.
 */
const SpinnerLoader = () => {
  return (
    <RotatingSVG
      width="16"
      height="16"
      viewBox="0 0 100 100"
      stroke={Colors.Maroon90}
    >
      <GrowShrinkCirclePath
        strokeOpacity={0.8}
        cx={50}
        cy={50}
        r={45}
        strokeWidth={10}
      />
    </RotatingSVG>
  );
};
