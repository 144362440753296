import { useLocalStorage } from 'react-use';

const LOCAL_STORAGE_REFRESH_KEY =
  process.env.REACT_APP_REFRESH_TOKEN_STORAGE_KEY;
if (!LOCAL_STORAGE_REFRESH_KEY) {
  throw new Error('REACT_APP_REFRESH_TOKEN_STORAGE_KEY env var not set');
}

const LOCAL_STORAGE_BEARER_KEY = process.env.REACT_APP_BEARER_TOKEN_STORAGE_KEY;
if (!LOCAL_STORAGE_BEARER_KEY) {
  throw new Error('REACT_APP_BEARER_TOKEN_STORAGE_KEY env var not set');
}

interface BearerTokenAPI {
  bearerToken?: string;
  setBearerToken: (bearerToken?: string) => void;
  removeBearerToken: () => void;
}

interface RefreshTokenAPI {
  refreshToken?: string;
  setRefreshToken: (refreshToken?: string) => void;
  removeRefreshToken: () => void;
}

export interface UseTokenStorageAPI extends BearerTokenAPI, RefreshTokenAPI {}

export const useTokenStorage = (): UseTokenStorageAPI => {
  const [bearerToken, setBearerToken, removeBearerToken] = useLocalStorage<
    string | undefined
  >(LOCAL_STORAGE_BEARER_KEY);
  const [refreshToken, setRefreshToken, removeRefreshToken] =
    useLocalStorage<string>(LOCAL_STORAGE_REFRESH_KEY);

  return {
    bearerToken,
    setBearerToken,
    removeBearerToken,
    refreshToken,
    setRefreshToken,
    removeRefreshToken,
  };
};
