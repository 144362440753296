import styled from '@emotion/styled/macro';
import { Colors } from '@robinpowered/design-system';

export const WelcomeHeading = styled.h1`
  color: ${Colors.Gray100};
  font-size: 72px;
  font-weight: 400;
  line-height: 120px;
  margin-bottom: 48px;
  margin-top: 0;
  padding-right: 180px;
`;
