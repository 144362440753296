import styled from '@emotion/styled/macro';
import { Colors } from '@robinpowered/design-system';

export const InputError = styled.p`
  color: ${Colors.RedProduct};
  font-size: 16px;
  line-height: 22px;
  margin-bottom: 0;
  margin-top: 16px;
`;
