import React from 'react';
import { Body06, Colors } from '@robinpowered/design-system';
import styled from '@emotion/styled/macro';
import { useTranslation } from 'react-i18next';
import poweredByRobin from '../media/logo-maroon.png';

const { REACT_APP_RELEASE_VERSION: RELEASE_VERSION } = process.env;

const _Footer = (
  _props: unknown,
  ref: React.LegacyRef<HTMLElement>
): JSX.Element => {
  const { t } = useTranslation('common');
  const footerText = `${t('footer_alt_text')} ${
    RELEASE_VERSION ? RELEASE_VERSION : ''
  }`;

  return (
    <FooterWrapper ref={ref}>
      <Logo width="150px" alt={t('footer_alt_text')} src={poweredByRobin} />
      <Version>{footerText}</Version>
    </FooterWrapper>
  );
};

export const Footer = React.forwardRef(_Footer);

const FooterWrapper = styled.footer`
  display: flex;
  align-items: baseline;
  justify-content: center;
  padding: 0 30px 60px;
`;

const Logo = styled.img`
  position: relative;
  width: 20px;
  bottom: -6px; // Visually align
`;

const Version = styled(Body06)`
  color: ${Colors.Gray40};
  margin-left: 16px;
`;
