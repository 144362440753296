import { useState, useEffect, useRef, useCallback, RefCallback } from 'react';

/**
 * A hook that returns whether a ref to a given rendered element
 * has ever entered the viewport—if the user has viewed it.
 *
 * Example usage:
 *   const {hasViewedRef, setRef} = useHasViewedRef();
 *   return (<div ref={setRef} />)
 */
export const useHasViewedRef = (): {
  /**
   * A callback ref to set on a component, allowing us to detect when that
   * component has entered the viewport.
   *
   * Use it like this: `<div ref={setRef} />`
   */
  setRef: RefCallback<Element>;
  resetViewTrigger: () => void;
  hasViewedRef: boolean;
} => {
  const [hasViewedRef, setHasViewedRef] = useState(false);
  const [observer, setObserver] = useState<IntersectionObserver>();
  const ref = useRef(null);

  // Using a callback ref allows us to set the observer after
  // the initial render, since the ref initializes as null.
  // Pattern: https://reactjs.org/docs/refs-and-the-dom.html#callback-refs
  const setRef = useCallback(
    (node) => {
      // Only fire once, when the ref is set
      if (!ref.current && node && observer) {
        observer.observe(node);
      }
      ref.current = node;
    },
    [observer]
  );

  const resetViewTrigger = useCallback(() => {
    setHasViewedRef(false);
  }, []);

  // Create an observer to detect when the ref has entered the viewport.
  useEffect(() => {
    const newObserver = new IntersectionObserver(([entry]) => {
      if (entry.isIntersecting && !hasViewedRef) {
        setHasViewedRef(true);
      }
    });
    setObserver(newObserver);
    return () => {
      newObserver.disconnect();
    };
  }, [hasViewedRef]);

  return {
    setRef,
    resetViewTrigger,
    hasViewedRef,
  };
};
