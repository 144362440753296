import { v4 as uuidv4 } from 'uuid';
import { sign } from 'jsonwebtoken';
import { certObfuscation } from 'constants/certObfuscation';

export const generateSignedJwt = (
  signingCert: string,
  tenantId: string,
  deviceId: string
): string =>
  sign(
    {
      tokenType: 'arrival-display',
      tenantId: tenantId,
      exp: Math.floor(Date.now() / 1000) + 60 * 30,
      iat: Math.floor(Date.now() / 1000),
    },
    Buffer.from(
      // Remove the obfuscation string from the cert before using it to sign the JWT
      signingCert.substr(0, signingCert.length - certObfuscation.length),
      'base64'
    ),
    {
      subject: deviceId,
      issuer: 'robin-arrival-display',
      audience: 'robinpowered.com',
      jwtid: uuidv4(),
      algorithm: 'HS256',
    }
  );
